import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { Button, Text, Paragraph, FormLabel, InputText, OptionsButton } from 'prixa-design-kit/dist';
import { PreconReply } from '../../wrappers/diagnostic/ConversationPage';

export interface PrecondProps {
  setConvoState: (params: { state: string }) => void;
  callFunction: (reply?: object) => void;
  title: Array<string>;
  loading?: boolean;
  list: Array<PreconReply>;
  preconditionData: any;
  setPreconditionData: any;
}

const BasicPreconditionContext = createContext({} as any);

export const BasicPrecondition: React.FC<PrecondProps> = (props: PrecondProps) => {
  const [showMonth, setShow] = useState(false);
  const [genderList, setGenderList] = useState<Array<object>>([]);
  const [ageMonth, setAgeMonth] = useState(0);
  const [ageYear, setAgeYear] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [gender, setGender] = useState({});
  const [errAgeMonth, setErrAgeMonth] = useState(false);
  const [errAgeYear, setErrAgeYear] = useState(false);
  const [errHeight, setErrHeight] = useState(false);
  const [errWeight, setErrWeight] = useState(false);
  const [errGender, setErrGender] = useState(true);
  const [isDisabled, setDisabled] = useState(true);

  const isFirstRunAge = useRef(true);
  useEffect(() => {
    if (Number(ageYear) > 0) {
      setShow(false);
      setAgeMonth(0);
    } else {
      setShow(true);
    }

    if (isFirstRunAge.current) {
      isFirstRunAge.current = false;
      return;
    }

    if (Number(ageMonth) > 0 && Number(ageMonth) < 13 && Number(ageYear) === 0) {
      setErrAgeMonth(false);
      setErrAgeYear(false);
    } else if (Number(ageYear) < 0 || Number(ageYear) > 100) {
      setErrAgeYear(true);
    } else if (Number(ageYear) > 0 && Number(ageYear) < 100) {
      setErrAgeMonth(false);
      setErrAgeYear(false);
    } else {
      setErrAgeMonth(true);
    }
  }, [ageMonth, ageYear]);

  const isFirstRunHeight = useRef(true);
  useEffect(() => {
    if (isFirstRunHeight.current) {
      isFirstRunHeight.current = false;
      return;
    }

    if (height > 0 && height < 300) {
      setErrHeight(false);
    } else {
      setErrHeight(true);
    }
  }, [height]);

  const isFirstRunWeight = useRef(true);
  useEffect(() => {
    if (isFirstRunWeight.current) {
      isFirstRunWeight.current = false;
      return;
    }

    if (weight > 0 && weight < 500) {
      setErrWeight(false);
    } else {
      setErrWeight(true);
    }
  }, [weight]);

  useEffect(() => {
    if (JSON.stringify(gender) === '{}') {
      setErrGender(true);
    } else {
      setErrGender(false);
    }
  }, [gender]);

  useEffect(() => {
    const tempData: Array<object> = [];
    for (let index = 0; index < props.list.length; index++) {
      if (props.list[index].type === 'gender') {
        tempData.push(props.list[index]);
      }
    }
    setGenderList(tempData);
  }, [props.list]);

  useEffect(() => {
    const isAgeMonthValid = !errAgeMonth && ageMonth > 0;
    const isAgeYearValid = !errAgeYear && ageYear > 0;
    const isHeightValid = !errHeight && height !== 0;
    const isWeightValid = !errWeight && weight !== 0;
    const isGenderValid = !errGender && gender !== {};
    const isAllValid = (isAgeMonthValid || isAgeYearValid) && isHeightValid && isWeightValid && isGenderValid;
    isAllValid ? setDisabled(false) : setDisabled(true);
  }, [errAgeMonth, ageMonth, errAgeYear, ageYear, errHeight, height, errWeight, weight, errGender, gender]);

  let temptPeconditionData: any;

  const firstSubmit = (event: any) => {
    event.preventDefault();
    temptPeconditionData = [
      {
        height: Number(height),
        weight: Number(weight),
        type: 'bmi',
      },
      {
        ageYear: Number(ageYear),
        ageMonth: Number(ageMonth),
        type: 'age',
      },
    ];
    temptPeconditionData.push(gender);
    props.setPreconditionData(temptPeconditionData);
    props.setConvoState({ state: 'askPrecondition1' });
  };

  return (
    <div className="prixa-container">
      <form
        onSubmit={event => {
          firstSubmit(event);
        }}
      >
        <div className="prixa-title">
          <Paragraph scale="question">Mohon lengkapi informasi berikut:</Paragraph>
          <Paragraph scale="caption" className="prixa-title-long-sub">
            Informasi ini akan membantu Prixa <br />
            menentukan pertanyaan yang relevan bagi Anda.
          </Paragraph>
          <BasicPreconditionContext.Provider value={{ genderList, setGender, gender }}>
            <GenderSection />
          </BasicPreconditionContext.Provider>
          <BasicPreconditionContext.Provider value={{ setAgeYear, errAgeYear, showMonth, setAgeMonth, errAgeMonth }}>
            <AgeSection />
          </BasicPreconditionContext.Provider>
          <BasicPreconditionContext.Provider value={{ setHeight, errHeight, setWeight, errWeight }}>
            <WeightHeightSection />
          </BasicPreconditionContext.Provider>
        </div>
        <div className="prixa-right-button">
          <Button type="submit" size="option" variant="primary" disabled={isDisabled} className="dav-special">
            Lanjut
          </Button>
        </div>
      </form>
    </div>
  );
};

const GenderSection = () => {
  const { genderList, setGender, gender } = useContext(BasicPreconditionContext);

  return (
    <div className="row">
      <FormLabel small>JENIS KELAMIN</FormLabel>
      <div className="flex-row">
        <OptionsButton data={genderList} setData={setGender} selected={gender} />
      </div>
    </div>
  );
};

const AgeSection = () => {
  const { setAgeYear, errAgeYear, showMonth, setAgeMonth, errAgeMonth } = useContext(BasicPreconditionContext);

  return (
    <div className="row margin-tinyB">
      <FormLabel small>UMUR</FormLabel>
      <div className="flex-inline">
        <div className="flex-inline margin-tinyR">
          <InputText
            type="number"
            placeholder="99"
            max={100}
            small
            maxLength={3}
            setData={setAgeYear}
            errors={errAgeYear}
          />
          <Text scale="content" className="margin-tinyX">
            tahun
          </Text>
        </div>
        {!showMonth || (
          <div className="flex-inline margin-tinyR">
            <InputText
              type="number"
              placeholder="12"
              maxLength={2}
              setData={setAgeMonth}
              max={12}
              small
              errors={errAgeMonth}
            />
            <Text scale="content" className="margin-tinyX">
              bulan
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

const WeightHeightSection = () => {
  const { setHeight, errHeight, setWeight, errWeight } = useContext(BasicPreconditionContext);

  return (
    <div className="row flex-inline">
      <div className="margin-baseR">
        <FormLabel small>TINGGI (CM)</FormLabel>
        <InputText type="number" max={300} setData={setHeight} placeholder="170" small errors={errHeight} />
      </div>
      <div className="margin-baseR">
        <FormLabel small>BERAT (KG)</FormLabel>
        <InputText type="number" max={500} setData={setWeight} placeholder="65" small errors={errWeight} />
      </div>
    </div>
  );
};
