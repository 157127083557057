import axios from 'axios';
import { getPartnerID, getAppID } from '../utils/constant';

export const signal = axios.CancelToken.source();

export function getAPI(url: string, cancelToken?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        cancelToken,
        headers: {
          Authorization: localStorage.getItem('loginToken')
            ? `Bearer ${localStorage.getItem('loginToken')}`
            : undefined,
          'Content-Type': 'text/plain',
          'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
          'X-Partner-Id': getPartnerID,
          'X-Partner-App-Id': getAppID,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function postAPI(url: string, data: object, header?: object) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: url,
        data,
        headers: header || {
          'Content-Type': 'text/plain',
          'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
          'X-Partner-Id': getPartnerID,
          'X-Partner-App-Id': getAppID,
          Authorization: localStorage.getItem('loginToken')
            ? `Bearer ${localStorage.getItem('loginToken')}`
            : undefined,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function putAPI(url: string, data: object, header?: object) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: url,
        data,
        headers: header || {
          'Content-Type': 'text/plain',
          'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
          'X-Partner-Id': getPartnerID,
          'X-Partner-App-Id': getAppID,
          Authorization: localStorage.getItem('loginToken')
            ? `Bearer ${localStorage.getItem('loginToken')}`
            : undefined,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAPI(url: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: url,
        headers: {
          'Content-Type': 'text/plain',
          'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
          'X-Partner-Id': getPartnerID,
          'X-Partner-App-Id': getAppID,
          Authorization: localStorage.getItem('loginToken')
            ? `Bearer ${localStorage.getItem('loginToken')}`
            : undefined,
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
