import React from 'react';
import { Accordion } from 'prixa-design-kit/dist';

interface HotlineAccordion {
  hotline: string;
}

export const HotlineAccordion = ({ hotline }: HotlineAccordion) => {
  const hotlineSplitted = hotline.split('\n');

  return (
    <Accordion title={`Hubungi tenaga kesehatan untuk konsultasi`} danger className="padding-largeX">
      <span>
        {hotlineSplitted.map((line, i) => (
          <span key={i}>
            {line}
            <br />
          </span>
        ))}
      </span>
    </Accordion>
  );
};

export default HotlineAccordion;
