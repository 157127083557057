import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LoadPage } from '../wrappers/diagnostic/LoadPage';
import { getAPI, signal } from '../api/api-method';
import { USER_API } from '../api/api-url';
import moment from 'moment';

// AfterDiagnose Routes = Diagnosis ID is needed.
// Routes throw error page asking user to do diagnosis first (if NOT LOG IN)
// Routes redirect to home (if LOG IN)

export const AfterDiagnoseRoute = ({ component: Component, ...rest }: any) => {
  localStorage.removeItem('sesId');

  if (localStorage.getItem('loginToken')) {
    return <Redirect to="/home" />;
  } else {
    return <Route {...rest} component={Component} />;
  }
};

// Logged Routes = User need to be logged to access pages
export const LoggedRoute = ({ component: Component, ...rest }: any) => {
  const [routeState, setRouteState] = React.useState('load');
  const time = 1;

  localStorage.removeItem('sesId');

  /* eslint-disable */
  React.useEffect(() => {
    getAPI(USER_API.INFO)
      .then((res: any) => {
        localStorage.setItem('profileData', JSON.stringify(res.profileData));
        const isBookingDatePage = rest.path === '/booking-date';
        const isBookingTimePageValid = rest.path === '/booking-time';
        const isBookingDataPageValid = rest.path === '/booking-data';
        const isBookingConfirmationPageValid = rest.path === '/booking-confirmation';

        if (isBookingDatePage || isBookingTimePageValid || isBookingDataPageValid || isBookingConfirmationPageValid) {
          if (localStorage.getItem('bookingAppointmentData')) {
            const doctorDataLocalStorage = JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).doctorData
              ? JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).doctorData
              : {};
            const bookingDateLocalStorage = moment(
              String(JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).bookingDate),
            ).isValid()
              ? new Date(JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).bookingDate)
              : null;
            const bookingTimeLocalStorage = JSON.parse(String(localStorage.getItem('bookingAppointmentData')))
              .bookingTime
              ? JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).bookingTime
              : {};
            const bookingDataLocalStorage = JSON.parse(String(localStorage.getItem('bookingAppointmentData')))
              .bookingData
              ? JSON.parse(String(localStorage.getItem('bookingAppointmentData'))).bookingData
              : {};

            if (isBookingDatePage) {
              if (doctorDataLocalStorage !== {}) {
                // console.log(bookingDateLocalStorage);
                rest.bookingProps.setDoctorData(doctorDataLocalStorage);
                rest.bookingProps.setDate(bookingDateLocalStorage);
              } else {
                setRouteState('booking');
              }
            }

            if (isBookingTimePageValid) {
              if (moment(String(bookingDateLocalStorage)).isValid()) {
                rest.bookingProps.setDoctorData(doctorDataLocalStorage);
                rest.bookingProps.setDate(bookingDateLocalStorage);
                rest.bookingProps.setTime(bookingTimeLocalStorage);
              } else {
                setRouteState('booking');
              }
            }

            if (isBookingDataPageValid) {
              if (moment(String(bookingDateLocalStorage)).isValid() && bookingTimeLocalStorage !== {}) {
                rest.bookingProps.setDoctorData(doctorDataLocalStorage);
                rest.bookingProps.setDate(bookingDateLocalStorage);
                rest.bookingProps.setTime(bookingTimeLocalStorage);
                rest.bookingProps.setBookingData(bookingDataLocalStorage);
              } else {
                setRouteState('booking');
              }
            }

            if (isBookingConfirmationPageValid) {
              if (
                moment(String(bookingDateLocalStorage)).isValid() &&
                bookingTimeLocalStorage !== {} &&
                bookingDataLocalStorage !== {}
              ) {
                rest.bookingProps.setDoctorData(doctorDataLocalStorage);
                rest.bookingProps.setDate(bookingDateLocalStorage);
                rest.bookingProps.setTime(bookingTimeLocalStorage);
                rest.bookingProps.setBookingData(bookingDataLocalStorage);
              } else {
                setRouteState('booking');
              }
            }
          } else {
            setRouteState('booking');
          }
        }
        setRouteState('verified');
      })
      .catch(err => {
        // console.log(err);
        setRouteState('');
        localStorage.removeItem('bookingAppointmentData');
        localStorage.removeItem('loginToken');
        localStorage.removeItem('profileData');
        localStorage.removeItem('personID');
        localStorage.removeItem('isVerified');
        localStorage.removeItem('profileData');
        window.location.href = '/user';
      });
    signal.cancel('');
  }, [time]);
  /* eslint-ensable */

  if (routeState === 'load') {
    return <Route path={rest.path} component={LoadPage} />;
  } else if (routeState === 'verified') {
    return <Route path={rest.path}>{rest.children}</Route>;
  } else if (routeState === 'booking') {
    return <Redirect to="/booking" />;
  } else {
    return <Redirect to="/user" />;
  }
  // return <React.Fragment>{selectedComponent}</React.Fragment>;
};
