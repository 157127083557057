import React from 'react';
import { Text, Button, IconSolid, Box, Card, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import SummarySideSheet from '../SummarySideSheet';
import EmailSideSheet from '../EmailSideSheet';
import SurveySideSheet from '../SurveySideSheet';
import ArticleSideSheet, { ArticleType } from '../ArticleSideSheet';
import { findInProfiles, getHighestDisease, getConvertedDisease } from './utils';
import { priority, triageMessage, classifyIconColor, classifyTextColor } from './constant';
import { getAPI } from '../../../api/api-method';
import { DIAGNOSTIC_API } from '../../../api/api-url';
import CtaFeature from '../../../components/cta-feature/CtaFeature';
import { showUserManagement } from '../../../utils/constant';
import { UseTracking } from '../../../utils/useTracking';
import { sessionId, currentState } from '../../../api/api-utils';

const ResultPage = (props: any) => {
  const { user, userDetails, profiles, symptoms, diseases } = props.diagnoseResult;

  const highestDisease = getHighestDisease(diseases);
  const summaryMessage = highestDisease.triage === priority.homecare ? triageMessage.homecare : triageMessage.opd;

  const disclaimerProps = {
    user: user,
    profiles: profiles,
    symptoms: symptoms,
    userDetails: userDetails,
    sessionId: props.sessionId,
  };

  return (
    <div className="prixa-container is-top is-full">
      <Summary summaryMessage={summaryMessage} />
      <ResultList convertedDisease={getConvertedDisease(diseases)} />
      <Disclaimer {...disclaimerProps} />
      {showUserManagement() ||
      (localStorage.getItem('RDTValid') === 'true' && process.env.REACT_APP_ACTIVE === 'covid') ? (
        <div className="margin-largeY margin-largeX">
          <CtaFeature />
        </div>
      ) : (
        <span />
      )}
      <Finish sessionId={props.sessionId} />
    </div>
  );
};

const Summary = ({ summaryMessage }: any) => {
  return (
    <Box
      bordered
      variant={summaryMessage.boxColor}
      className="margin-largeX margin-baseT margin-largeB"
      style={{ padding: '24px' }}
    >
      <div className={`text-center color-${summaryMessage.boxColor}`}>
        <span className="prixa-summary-message-title">{summaryMessage.titleSummary}</span>
        <p className="prixa-summary-message-detail">{summaryMessage.detailSummary}</p>
      </div>
    </Box>
  );
};

const ResultList = ({ convertedDisease }: any) => {
  const [modalArticle, setModalArticle] = React.useState(false);
  const [article, setArticle] = React.useState<ArticleType | undefined>(undefined);
  const [articleTitle, setArticleTitle] = React.useState('');

  const viewArticle = (id: string, name: string, url: string) => {
    getAPI(DIAGNOSTIC_API.DISEASE_ARTICLE(id)).then(async (res: any) => {
      const articleLink = { url: url };
      const articleData = { ...res, ...articleLink };
      setArticleTitle(name);
      setArticle(articleData);
      setModalArticle(true);
      openSideSheet();
    });
  };

  return (
    <div className="prixa-list-container">
      {convertedDisease.map((disease: any, i: number) => {
        return (
          <Card key={i} style={{ padding: '16px', margin: '20px 40px' }}>
            <div className="prixa-list-title">
              <Text scale="question">{disease.name}</Text>
            </div>
            {disease.description ? (
              <div className="prixa-list-subtitle prixa-disease-short-description">
                <Text scale="content">{disease.description}</Text>
              </div>
            ) : (
              ''
            )}

            <div>
              <div className="prixa-disease-advance-info margin-smallT">
                <IconSolid
                  backgroundColor={classifyIconColor[disease.classify]}
                  backgroundSize="18px"
                  color="white"
                  margin="0px"
                  type="faPercentage"
                  width="8px"
                  style={{ alignItems: 'center' }}
                >
                  <Text
                    className="margin-microL"
                    style={{ lineHeight: '17px' }}
                    scale={classifyTextColor[disease.classify]}
                  >
                    {disease.evidenceLevel}
                  </Text>
                </IconSolid>
              </div>

              <div className="prixa-disease-advance-info">
                <IconSolid
                  backgroundColor={'danger'}
                  backgroundSize="18px"
                  color="white"
                  margin="0px"
                  type="faExclamation"
                  width="4px"
                  style={{ alignItems: 'center' }}
                >
                  <Text className="margin-microL" style={{ lineHeight: '17px', flex: 1 }} scale={'headerSubtitle'}>
                    {disease.prognosis || '-'}
                  </Text>
                </IconSolid>
              </div>
            </div>

            {disease.article !== '#' ? (
              <span className="prixa-list-button">
                <Button
                  onClick={() => {
                    const diseaseTrack = {
                      diseaseId: disease.id,
                      diseaseName: disease.name,
                      diseaseScore: String(disease.score),
                      diseaseTriage: String(disease.triage),
                    };
                    UseTracking({
                      event: `Article ${disease.name} clicked`,
                      properties: { sessionId, state: currentState, ...diseaseTrack },
                    });
                    viewArticle(disease.id, disease.name, disease.article);
                  }}
                  size="small"
                  className="dav-special"
                  variant="outline"
                >
                  Lebih Lanjut
                </Button>
              </span>
            ) : (
              ''
            )}
          </Card>
        );
      })}
      {article && (
        <Sidesheet
          setModal={setModalArticle}
          show={modalArticle}
          className="prixa-sidesheet"
          title={articleTitle}
          content={<ArticleSideSheet {...article}></ArticleSideSheet>}
        />
      )}
    </div>
  );
};

const Disclaimer = (props: any) => {
  const { user, profiles, symptoms, userDetails, sessionId } = props;
  const [modalSummary, setModalSummary] = React.useState(false);
  const [modalEmail, setModalEmail] = React.useState(false);
  const gender = findInProfiles(profiles, 'gender');
  const age = userDetails.ageYear ? userDetails.ageYear + ' tahun ' : '';
  const month = userDetails.ageMonth ? userDetails.ageMonth + ' bulan' : '';
  const ageResult = age + month;

  return (
    <React.Fragment>
      <div className="prixa-result-content text-center margin-largeY color-white">
        <Text>
          <b>Perlu diingat, Hasil Prixa ini bukan pengganti diagnosis medis dokter ya. </b>
        </Text>
        <Text className="margin-baseB">
          Segera konsultasi dengan dokter jika keluhan memburuk atau terdapat keluhan lain yang Anda rasakan.
        </Text>
        <span
          onClick={() => {
            UseTracking({ event: `Summary complaint clicked`, properties: { sessionId, state: currentState } });
            setModalSummary(true);
            openSideSheet();
          }}
        >
          <Button
            style={{ color: '#FFFFFF', borderColor: '#FFFFFF' }}
            size="large"
            variant="outline"
            className="dav-special margin-smallB"
            width="220px"
          >
            Lihat Rangkuman Keluhan
          </Button>
        </span>
        <span
          onClick={() => {
            UseTracking({ event: `Send to email clicked`, properties: { sessionId, state: currentState } });
            setModalEmail(true);
            openSideSheet();
          }}
        >
          <Button
            style={{ color: '#FFFFFF', borderColor: '#FFFFFF', marginBottom: '40px' }}
            size="large"
            className="dav-special"
            variant="outline"
            width="220px"
          >
            Kirim Hasil Prixa ke Email
          </Button>
        </span>
      </div>
      <Sidesheet
        setModal={setModalSummary}
        show={modalSummary}
        className="prixa-sidesheet"
        title="Rangkuman Keluhan"
        subtitle={gender + ', ' + ageResult}
        content={<SummarySideSheet user={user} profiles={profiles} symptoms={symptoms} userDetails={userDetails} />}
      />
      <Sidesheet
        setModal={setModalEmail}
        className="prixa-sidesheet"
        show={modalEmail}
        title="Kirim Hasil Prixa"
        content={<EmailSideSheet sessionId={sessionId}></EmailSideSheet>}
      />
    </React.Fragment>
  );
};

const Finish = ({ sessionId }: any) => {
  const [modalSurvey, setModalSurvey] = React.useState(false);

  return (
    <React.Fragment>
      <span
        onClick={() => {
          UseTracking({ event: `Finish clicked`, properties: { sessionId, state: currentState } });
          setModalSurvey(true);
          openSideSheet();
        }}
      >
        <Button size="full" variant="primary">
          Selesai
        </Button>
      </span>
      <Sidesheet
        setModal={setModalSurvey}
        show={modalSurvey}
        className="prixa-sidesheet"
        title="Survey Layanan"
        content={<SurveySideSheet sessionId={sessionId}></SurveySideSheet>}
      />
    </React.Fragment>
  );
};

export { ResultPage };
