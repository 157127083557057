import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './styles/base.scss';
import './styles/media.scss';
import { PrixaStyle } from 'prixa-design-kit/dist';
import { ErrorPage } from './wrappers/diagnostic/ErrorPage';
import { LoadPage } from './wrappers/diagnostic/LoadPage';
import App from './wrappers/App';
import * as serviceWorker from './serviceWorker';
import { getAPI } from './api/api-method';
import { PARTNER_APP_API } from './api/api-url';
import Container from './components/container/Container';
import { BrowserRouter } from 'react-router-dom';
import { getPartnerID, getAppID, partnerTheme, setAncillary, getUID } from './utils/constant';

declare global {
  interface Window {
    CRISP_WEBSITE_ID: string;
    $crisp: any;
  }
}

const CallAPI: React.FC = () => {
  const [load, setLoader] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [error, setError] = useState('');
  const [{ title, subtitle }, setHeader] = useState({ title: '', subtitle: '' });
  const [percentage, setPercentage] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const doSetShowMenu = useCallback((menu = true) => {
    setShowMenu(menu);
  }, []);

  const doSetHeader = useCallback((title?: string, subtitle?: string) => {
    setHeader({ title: title || '', subtitle: subtitle || '' });
  }, []);

  function doSetPercentage(percent?: number) {
    setPercentage(percent || 0);
  }

  function doSetError(error?: string) {
    setError(error || '');
  }

  const doSetEditMode = (editMode = false): void => {
    setEditMode(editMode);
  };

  useEffect(() => {
    getAPI(PARTNER_APP_API.PARTNER_APP_ID(getPartnerID, getAppID))
      .then((res: any) => {
        localStorage.setItem('getPartnerID', getPartnerID);
        localStorage.setItem('getAppID', getAppID);
        localStorage.setItem('getUID', getUID);
        localStorage.setItem('telemedicineSDKURL', res.data.telemedicineSDKURL || '');
        partnerTheme.set(res.data.theme);
        setAncillary(res.data.ancillary);
        setLoader(false);
      })
      .catch(err => {
        setError('Maaf, ada kesalahan dari kami');
        setLoader(false);
      });
  }, []);

  return (
    <BrowserRouter>
      <Container
        title={title}
        subtitle={subtitle}
        percentage={percentage}
        showMenu={showMenu}
        editMode={editMode}
        setEditMode={doSetEditMode}
        content={
          <React.Fragment>
            {load ? (
              <LoadPage />
            ) : error === '' ? (
              <App
                setHeader={doSetHeader}
                setPercentage={doSetPercentage}
                setError={doSetError}
                setMenu={doSetShowMenu}
                editMode={editMode}
                setEditMode={doSetEditMode}
              />
            ) : (
              <ErrorPage text={error} goTo="https://prixa.ai" goToString="Ke halaman Prixa.ai" />
            )}
          </React.Fragment>
        }
      />
    </BrowserRouter>
  );
};

ReactDOM.render(
  <PrixaStyle>
    <CallAPI />
  </PrixaStyle>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
