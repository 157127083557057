import React, { useEffect, useContext } from 'react';
import { Image, Text } from 'prixa-design-kit/dist';
import { SpecialityType } from './SpecialitiesSection';
import { getAPI } from '../../../api/api-method';
import { BOOKING_API } from '../../../api/api-url';
import { LoadPage } from '../../diagnostic/LoadPage';
import { BookingContext } from '../booking-page/BookingContext';
import { useHistory } from 'react-router-dom';

const baseIconURL = `${process.env.REACT_APP_ASSET_URL}/Appointment Booking/`;

export const AllSpecialityPage = ({ setHeader, setMenu }: any) => {
  const { filterData, setFilterData, allSpecialities, setAllSpecialities } = useContext(BookingContext);
  const history = useHistory();

  useEffect(() => {
    setHeader('Spesialisasi');
    setMenu(false);

    if (!allSpecialities)
      Promise.all([getAPI(BOOKING_API.SPECIALITIES), getAPI(BOOKING_API.SPECIALITIES + '?page=2')]).then(
        (allResp: any) => {
          const merge = allResp[0].data.concat(allResp[1].data);
          setAllSpecialities(merge);
        },
      );
  }, [allSpecialities, setAllSpecialities, setHeader, setMenu]);

  if (!allSpecialities) return <LoadPage />;

  const clickedSpecialities = (id: string, name: string) => {
    setFilterData({
      ...filterData,
      specialities: { id, name },
    });
    history.push('/search-doctor');
  };

  return (
    <div className="prixa-container is-top">
      {filterData.hospitals.name ? (
        <div className="margin-baseB padding-baseX">
          <Text scale="content">
            Ditemukan <b>{allSpecialities.length} Spesialisasi</b> di <b>{filterData.hospitals.name}</b>
          </Text>
        </div>
      ) : (
        ''
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {allSpecialities.map(({ id, nameIndo }: SpecialityType, i: number) => (
          <div
            key={i}
            className={`text-center margin-smallB padding-microX`}
            style={{
              fontSize: 12,
              display: 'flex',
              flexDirection: 'column',
              width: '30%',
              wordWrap: 'break-word',
              cursor: 'pointer',
            }}
            onClick={() => clickedSpecialities(id, nameIndo)}
          >
            <Image
              avatar
              size="tiny"
              style={{ height: '64px', width: '64px' }}
              src={`${baseIconURL}Icon ${nameIndo}-min.png`}
            />
            {nameIndo}
          </div>
        ))}
      </div>
    </div>
  );
};
