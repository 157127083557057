import React, { useState, useContext } from 'react';
import { Text, Button, openSideSheet } from 'prixa-design-kit/dist';
import { postAPI, signal } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import { useHistory } from 'react-router-dom';
import { LoginContext } from './LoginContext';
import { useInput } from '../../../utils/useInput';

export const LoginForm = () => {
  const { setError, setModal } = useContext(LoginContext);
  const [isLoad, setLoader] = useState(false);

  const email = useInput({
    label: 'Email',
    type: 'email',
    placeholder: 'nama@email.com',
    isValidate: false,
  });

  const password = useInput({
    label: 'Password',
    type: 'password',
    placeholder: 'Tulis Password',
    isValidate: false,
  });

  const inputList = [email.input, password.input];
  const history = useHistory();

  const login = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoader(true);
    postAPI(USER_API.LOGIN, {
      authData: {
        email: email.value,
        password: password.value,
        passwordConfirmation: password.value,
        diagnosticSessionID: localStorage.getItem('DiagnosisID'),
      },
    })
      .then((res: any) => {
        localStorage.setItem('loginToken', res.loginToken);
        localStorage.setItem('personID', res.personID);
        localStorage.setItem('isVerified', String(res.isVerified) || '');
        setLoader(false);
        signal.cancel('');
        history.push({
          pathname: `${localStorage.getItem('isVerified') === 'true' ? '/home' : '/unverified'}`,
        });
      })
      .catch(err => {
        setError(
          err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata
            ? err.response.data.details[0].metadata.errInd
            : 'Gangguan sistem, mohon coba kembali.',
        );
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoader(false);
      });
  };

  return (
    <form onSubmit={(event: React.FormEvent<HTMLFormElement>): void => login(event)}>
      {inputList.map((item, key: number) => {
        return (
          <div key={key} className="margin-baseB">
            {item}
          </div>
        );
      })}
      <div
        onClick={() => {
          setModal(true);
          openSideSheet();
        }}
        className="margin-tinyT"
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <Text style={{ cursor: 'pointer' }} scale="feedbackLink">
          Lupa password?
        </Text>
      </div>
      <div className="text-center margin-largeT">
        <Button onClick={(e: any) => login(e)} size="xLarge" variant="primary" spinner={isLoad}>
          Masuk
        </Button>
      </div>
    </form>
  );
};
