import React, { useEffect } from 'react';
import { Text, Paragraph, Button, Toast } from 'prixa-design-kit/dist';
import { postAPI } from '../../api/api-method';
import { DIAGNOSTIC_API } from '../../api/api-url';
import SurveySideSheet from './SurveySideSheet';
import { UseTracking } from '../../utils/useTracking';
import { currentState } from '../../api/api-utils';
import { useInput } from '../../utils/useInput';

const imgSendEmail = `${process.env.REACT_APP_ASSET_URL}/illustration/sent-to-mail.png`;

const pages = {
  SEND_EMAIL_PAGE: 'Send Email Page',
  SEND_EMAIL_SUCCESS_PAGE: 'Send Email Success Page',
  SURVEY_PAGE: 'Survey Page',
};

const EmailSideSheet = (props: any) => {
  const [emailPage, setEmailPage] = React.useState(pages.SEND_EMAIL_PAGE);

  if (emailPage === pages.SEND_EMAIL_PAGE) {
    return <SendEmailPage sessionId={props.sessionId} setPage={setEmailPage}></SendEmailPage>;
  } else if (emailPage === pages.SEND_EMAIL_SUCCESS_PAGE) {
    return <SendEmailSuccessPage setPage={setEmailPage}></SendEmailSuccessPage>;
  } else if (emailPage === pages.SURVEY_PAGE) {
    return <SurveySideSheet sessionId={props.sessionId}></SurveySideSheet>;
  }

  return <div></div>;
};

const SendEmailPage = (props: any) => {
  const [sent, setSent] = React.useState(false);
  const [isValid, setValid] = React.useState(false);
  const [isErrorMsg, setError] = React.useState('');

  /*eslint-disable */
  const name = useInput({
    label: 'Nama',
    type: 'text',
    placeholder: 'Nama panggilan Anda'
  });

  const email = useInput({
    label: 'Email',
    type: 'email',
    placeholder: 'nama@email.com'
  });
  /*eslint-enable */

  useEffect(() => {
    const isNameValid = name.value && !name.error;
    const isEmailValid = email.value && !email.error;
    const isAllValid = isNameValid && isEmailValid;
    isAllValid ? setValid(true) : setValid(false);
  }, [name, email]);

  const sendEmail = (event: any) => {
    setSent(true);
    event.preventDefault();

    postAPI(DIAGNOSTIC_API.SEND_EMAIL, {
      email: email.value,
      sessionId: props.sessionId,
      username: name.value,
    })
      .then(async (res: any) => {
        setSent(false);
        props.setPage(pages.SEND_EMAIL_SUCCESS_PAGE);
        UseTracking({ event: `Email sended`, properties: { sessionId: props.sessionId, state: currentState } });
      })
      .catch(err => {
        setError(
          err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata
            ? err.response.data.details[0].metadata.errInd
            : 'Gangguan sistem, mohon coba kembali.',
        );
        UseTracking({ event: `Email failed sent`, properties: { sessionId: props.sessionId, state: currentState } });
        setTimeout(() => {
          setError('');
        }, 3000);
        setSent(false);
      });
  };

  return (
    <React.Fragment>
      <form
        className="prixa-container is-top is-full-height"
        onSubmit={event => {
          sendEmail(event);
        }}
      >
        <div className="prixa-title">
          <Paragraph scale="heroTitle">Ke mana hasil pemeriksaan ini perlu dikirim?</Paragraph>
          <Paragraph scale="caption" className="prixa-title-sub">
            Data Anda tidak akan digunakan untuk keperluan lain
          </Paragraph>
        </div>
        <div className="margin-baseB">{name.input}</div>
        <div className="margin-largeB">{email.input}</div>
        {/* <div>
          <CheckBox label="Beri tahu saya informasi terbaru tentang Prixa"></CheckBox>
        </div> */}
        <div className="prixa-right-button">
          <Button type="submit" size="option" variant="primary" disabled={!isValid} spinner={sent} onClick={sendEmail}>
            Kirim
          </Button>
        </div>
      </form>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </React.Fragment>
  );
};

const SendEmailSuccessPage = (props: any) => {
  return (
    <div className="prixa-container">
      <div className="prixa-body">
        <div className="prixa-question-image">
          <img loading="lazy" width="240px" alt="Prixa Feedback" src={imgSendEmail} />
        </div>
        <div className="prixa-title">
          <Paragraph scale="question">Hasil pemeriksaan telah dikirim ke email Anda.</Paragraph>
          <Text scale="content" className="prixa-title-sub">
            Terima kasih sudah menggunakan Prixa, semoga Anda sehat selalu!
          </Text>
        </div>
      </div>
      <div className="prixa-right-button">
        <span onClick={() => props.setPage(pages.SURVEY_PAGE)}>
          <Button size="option" className="dav-special" variant="primary">
            Selesai
          </Button>
        </span>
      </div>
    </div>
  );
};

export default EmailSideSheet;
