export const urlValidation = () => {
  try {
    if (
      window.location.search === '' &&
      window.location.pathname.includes('partner/') &&
      window.location.pathname.includes('/app/')
    ) {
      return {
        partnerId: String(window.location.pathname.split('partner/')[1].split('/app/')[0]),
        appId: String(window.location.pathname.split('/app/')[1].split('/uid')[0]),
      };
    } else if (
      window.location.search !== '' &&
      window.location.search.includes('pId=') &&
      window.location.search.includes('&appId=')
    ) {
      return {
        partnerId: String(window.location.search.split('pId=')[1].split('&ap')[0]),
        appId: String(window.location.search.split('&appId=')[1].split('&uid')[0]),
      };
    } else if (
      localStorage.getItem('getPartnerID') &&
      localStorage.getItem('getAppID') &&
      localStorage.getItem('getPartnerID') !== '' &&
      localStorage.getItem('getAppID') !== ''
    ) {
      return {
        partnerId: String(localStorage.getItem('getPartnerID')),
        appId: String(localStorage.getItem('getAppID')),
      };
    } else {
      return { partnerId: '', appId: '' };
    }
  } catch {
    return { partnerId: '', appId: '' };
  }
};

export const getUIDParam = () => {
  if (window.location.search === '' && window.location.pathname.includes('/uid/')) {
    return String(window.location.pathname.split('/uid/')[1]);
  } else if (window.location.search !== '' && window.location.search.includes('&uid=')) {
    return String(window.location.search.split('&uid=')[1]);
  } else if (localStorage.getItem('getUID') !== '') {
    return String(localStorage.getItem('getUID'));
  } else {
    return '';
  }
};

export const getPartnerID = urlValidation().partnerId;

export const getAppID = urlValidation().appId;

export const getUID = getUIDParam();

let theme: any;

export const partnerTheme = {
  get: () => {
    return theme;
  },
  set: (data: object) => {
    theme = data;
  },
};

let telemedicine = false;
let article = false;
let pharmacyDelivery = false;
let labTest = false;
let appointmenBooking = false;
let insurance = false;

export const setAncillary = (data: any) => {
  telemedicine = data.telemedicine;
  article = data.article;
  pharmacyDelivery = data.pharmacyDelivery;
  labTest = data.labTest;
  appointmenBooking = data.appointmenBooking;
  insurance = data.insurance;
};

export const showUserManagement = () => {
  if (telemedicine || pharmacyDelivery || labTest || appointmenBooking || insurance) {
    return true;
  } else {
    return false;
  }
};

export const showTelemedFeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && telemedicine) {
    return true;
  } else if (telemedicine && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};

export const showBookingeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && appointmenBooking) {
    return true;
  } else if (appointmenBooking && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};

export const showArticleFeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && article) {
    return true;
  } else if (article && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};

export const showPharmacyFeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && pharmacyDelivery) {
    return true;
  } else if (pharmacyDelivery && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};

export const showLabFeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && labTest) {
    return true;
  } else if (labTest && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};

export const showInsuranceFeature = () => {
  if (process.env.REACT_APP_ACTIVE === 'covid' && localStorage.getItem('RDTValid') === 'true' && insurance) {
    return true;
  } else if (insurance && process.env.REACT_APP_ACTIVE !== 'covid') {
    return true;
  } else {
    return false;
  }
};
