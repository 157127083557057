import React from 'react';
import { Text, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import PrivacyPolicySideSheet from '../PrivacyPolicySideSheet';

export const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);

  const openPrivacyPolicy = () => {
    setPrivacyPolicy(true);
    openSideSheet();
  };

  return (
    <React.Fragment>
      <div className="margin-largeT margin-baseB">
        <div className="text-center">
          <Text scale="content" style={{ fontSize: '16px' }}>
            Dengan mendaftarkan akun, Anda menyetujui{' '}
            <span onClick={() => openPrivacyPolicy()}>
              <Text style={{ cursor: 'pointer', color: '#55B9E4', fontStyle: 'italic' }} scale="pageTitle">
                Syarat & Ketentuan
              </Text>
            </span>{' '}
            yang berlaku dari Prixa.
          </Text>
        </div>
      </div>
      <Sidesheet
        setModal={setPrivacyPolicy}
        show={privacyPolicy}
        className="prixa-sidesheet"
        title="Syarat dan Ketentuan"
        content={<PrivacyPolicySideSheet />}
      ></Sidesheet>
    </React.Fragment>
  );
};
