import React from 'react';
import { Toast } from 'prixa-design-kit/dist';
import { PrivacyPolicy } from './PrivacyPolicy';
import { SignUpForm } from './SignUpForm';
import { SignUpContext } from './SignUpContext';
import { GoogleLogin } from '../../../components/googleLogin/GoogleLogin';

const SignUpTab = () => {
  const [isErrorMsg, setError] = React.useState('');

  return (
    <React.Fragment>
      <PrivacyPolicy />
      <div className="prixa-container is-top">
        <SignUpContext.Provider value={{ setError }}>
          <SignUpForm />
        </SignUpContext.Provider>
        <GoogleLogin text="Lanjutkan dengan Google" />
        <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      </div>
    </React.Fragment>
  );
};

export default SignUpTab;
