import React from 'react';
import { Text, Box, Button, InputText, Card, Accordion } from 'prixa-design-kit/dist';
import CtaFeature from '../../components/cta-feature/CtaFeature';
import { showUserManagement } from '../../utils/constant';
import UseTracking from '../../utils/useTracking';
import { sessionId, currentState } from '../../api/api-utils';

export interface ArticleType {
  id: string;
  diseaseName: string;
  diseaseNameIndo: string;
  author: string;
  checkedBy: string;
  overview: string;
  advice: any;
  seeDoctor: string;
  howToPrevent: string;
  reference: string;
  relatedSymptom: Array<string>;
  nonMedicalTreatment?: string;
  medicalTreatment?: string;
  supportingLabs?: string;
  url: string;
  isValidForRDT?: boolean;
}

const ArticleSideSheet = (props: ArticleType) => {
  const [copyLabel, setCopyLabel] = React.useState('Salin');

  const copyText = (link: string) => {
    UseTracking({ event: `Article link copied`, properties: { sessionId, state: currentState, url: props.url } });
    const copyText = document.getElementById(link) as HTMLInputElement;
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setCopyLabel('Disalin!');
      setTimeout(() => {
        setCopyLabel('Salin');
      }, 1500);
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <div className="prixa-container is-top">
        <Card className="text-center">
          <Text scale="caption">
            <Text fontSize="12px">
              Ditulis oleh <b>{props.author}</b>
              <br />
              Diperiksa oleh <b>{props.checkedBy}</b>
            </Text>
          </Text>
        </Card>

        <div className="margin-largeT">
          <Text scale="question">Apakah kondisi ini?</Text>
        </div>
        <div className="margin-baseY">
          <Text scale="content">
            {props.overview.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>

        <Accordion title={'Keluhan/gejala yg biasa muncul:'} className="margin-baseY">
          <ul style={{ margin: 0, paddingLeft: 'inherit' }}>
            {props.relatedSymptom.map((symptom: string, i: number) => {
              return (
                <li key={i}>
                  <Text scale="content">{symptom}</Text>
                </li>
              );
            })}
          </ul>
        </Accordion>

        <div className="margin-largeT">
          <Text scale="question">Apa yang bisa dilakukan?</Text>
        </div>

        <div className="margin-baseY">
          <Text scale="content">
            {props.howToPrevent.split('\n').map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </div>

        {props.nonMedicalTreatment && (
          <Accordion title={'Penanganan tanpa obat:'} className="margin-baseY">
            {props.nonMedicalTreatment.split('\n').map((item: string, i: number) =>
              item.match(/^\d/) ? (
                <ul key={i} style={{ margin: 0, paddingLeft: '16px' }}>
                  <li>
                    <Text scale="content">{item.substring(item.indexOf('.') + 1)}</Text>
                  </li>
                </ul>
              ) : item !== '' ? (
                <div key={i} className="margin-tinyY">
                  <Text scale="content">{item}</Text>
                  <br />
                </div>
              ) : null,
            )}
          </Accordion>
        )}

        {props.medicalTreatment && (
          <Accordion title={'Penanganan menggunakan obat:'} className="margin-baseY">
            {props.medicalTreatment.split('\n').map((item: string, i: number) =>
              item.match(/^\d/) ? (
                <ul key={i} style={{ margin: 0, paddingLeft: '16px' }}>
                  <li>
                    <Text scale="content">{item.substring(item.indexOf('.') + 1)}</Text>
                  </li>
                </ul>
              ) : item !== '' ? (
                <div key={i} className="margin-tinyY">
                  <Text scale="content">{item}</Text>
                  <br />
                </div>
              ) : null,
            )}
          </Accordion>
        )}

        {props.supportingLabs && (
          <Accordion title={'Pemeriksaan tambahan:'} className="margin-baseY">
            {props.supportingLabs.split('\n').map((item: string, i: number) =>
              item.match(/^\d/) ? (
                <ul key={i} style={{ margin: 0, paddingLeft: '16px' }}>
                  <li>
                    <Text scale="content">{item.substring(item.indexOf('.') + 1)}</Text>
                  </li>
                </ul>
              ) : item !== '' ? (
                <div key={i} className="margin-tinyY">
                  <Text scale="content">{item}</Text>
                  <br />
                </div>
              ) : null,
            )}
          </Accordion>
        )}

        <div className="margin-largeT">
          <Text scale="question">Kapan perlu ke dokter?</Text>
        </div>

        <div className="margin-baseY">
          {props.seeDoctor.split('\n').map((item: any, key: number) =>
            item.match(/^\d/) ? (
              <ul key={key} style={{ margin: 0, paddingLeft: '16px' }}>
                <li>
                  <Text scale="content">{item.substring(item.indexOf('.') + 1)}</Text>
                </li>
              </ul>
            ) : item !== '' ? (
              <div key={key} className="margin-tinyY">
                <Text scale="content">{item}</Text>
                <br />
              </div>
            ) : null,
          )}
        </div>

        <Card className="text-center margin-largeT">
          <div className="margin-tinyB">
            <Text scale="headerSubtitle" fontSize="14px">
              Referensi
            </Text>
          </div>
          <Text scale="caption">
            {props.reference
              ? props.reference.split('\n').map((item: any, key: number) => {
                  return (
                    <Text key={key} fontSize="12px">
                      {item}
                      <br />
                    </Text>
                  );
                })
              : '-'}
          </Text>
        </Card>

        <div className="margin-smallB margin-largeT">
          <Box variant="default">
            <div className="text-center padding-small">
              <div className="padding-smallB">
                <Text scale="pageTitle" style={{ color: 'white' }}>
                  Ingin tahu informasi lebih lanjut tentang penyakit ini?
                </Text>
              </div>
              <div>
                <Button
                  href={props.url}
                  size="large"
                  target="_blank"
                  className="dav-special"
                  variant="outline"
                  style={{ color: 'white', borderColor: 'white' }}
                  onClick={() =>
                    UseTracking({
                      event: `External article link clicked`,
                      properties: { sessionId, state: currentState, url: props.url },
                    })
                  }
                >
                  Baca Selengkapnya
                </Button>
              </div>
            </div>
          </Box>
        </div>
        <div className="margin-baseB">
          <Box variant="secondary">
            <div className="text-center padding-smallY padding-tinyX">
              <div className="padding-smallB">
                <Text scale="pageTitle" style={{ color: 'white' }}>
                  Bagikan artikel ini:
                </Text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InputText
                  errors={false}
                  small={false}
                  validate={false}
                  value={props.url}
                  className="margin-tinyR"
                  id="article-link"
                  style={{ fontSize: '14px' }}
                  setData={() => undefined}
                />
                <Button
                  variant="outline"
                  className="dav-special"
                  style={{ color: 'white', borderColor: 'white', flex: 1, fontSize: '14px' }}
                  onClick={() => copyText('article-link')}
                >
                  {copyLabel}
                </Button>
              </div>
            </div>
          </Box>
        </div>
        {showUserManagement() ||
        (localStorage.getItem('RDTValid') === 'true' && process.env.REACT_APP_ACTIVE === 'covid') ? (
          <div className="margin-largeY">
            <CtaFeature />
          </div>
        ) : (
          <span />
        )}
      </div>
    </React.Fragment>
  );
};

export default ArticleSideSheet;
