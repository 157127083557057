import React from 'react';
import { Button, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import SurveySideSheet from '../../diagnostic/SurveySideSheet';
import WhatToDoNext from './WhatToDoNext';
import { HotlineAccordion } from './HotlineAccordion';
import EmailAndSummaryComplaint from './EmailAndSummaryComplaint';
import ResultDiseasesList from './ResultDiseasesList';
import { PARTNER_APP_API } from '../../../api/api-url';
import { getAPI } from '../../../api/api-method';
import { getPartnerID, getAppID, showUserManagement } from '../../../utils/constant';
import { SummaryMessage } from './SummaryMessage';
import './resultPageStyle.scss';
import CtaFeature from '../../../components/cta-feature/CtaFeature';

export interface UserType {
  gender?: string;
  weight: number;
  height: number;
  insurance?: any;
}

export interface UserDetailsType {
  ageMonth?: number;
  ageYear?: number;
}

export interface ProfileType {
  id?: string;
  name?: string;
  nameIndo?: string;
  description?: string;
  type?: string;
  order?: number;
}

export interface SymptomType {
  symptomID?: string;
  symptomName?: string;
  answer?: string;
  propNames?: Array<string>;
  chief?: boolean;
  symptomsTriage?: Array<string>;
}

export interface TriageDiseaseType {
  id: string;
  name: string;
  nameIndo: string;
  description: string;
}

export interface DiseaseType {
  id: string;
  name: string;
  description: string;
  likeliness: string;
  score: number;
  url: string;
  triage: TriageDiseaseType;
  prognosis?: string;
}

interface DiagnoseResultType {
  profiles: Array<ProfileType>;
  userDetails: UserDetailsType;
  user: UserType;
  symptoms: Array<SymptomType>;
}

export interface ContentCardType {
  id: string;
  title: string;
  snippet: string;
  type: string;
  sort: number;
  imageURL: string;
  externalLink: string;
}
export const ResultPage = (props: any) => {
  /*eslint-disable */
  const { diseases } = props.diagnoseResult;
  /*eslint-enable */
  const { user, userDetails, profiles, symptoms }: DiagnoseResultType = props.diagnoseResult;
  const [modalSurvey, setModalSurvey] = React.useState<boolean>(false);
  const [hotline, setHotline] = React.useState<string>('');
  const [formUrl, setFormUrl] = React.useState<string>('');
  const [rdtUrl, setRdtUrl] = React.useState<string>('');
  const [isReady, setIsReady] = React.useState(false);
  // const [contentCovid, setContentCovid] = React.useState([]);

  React.useEffect(() => {
    getAPI(PARTNER_APP_API.PARTNER_APP_META(getPartnerID, getAppID))
      .then((resp: any) => {
        setHotline(resp.metadata.hotline);
        setFormUrl(resp.metadata.formURL);
        setRdtUrl(resp.metadata.rdtURL);
        setIsReady(true);
      })
      .catch(() => setIsReady(true));

    // getAPI(DIAGNOSTIC_API.CONTENT('covid')).then((resp: any) => {
    //   const sorted =
    //     resp.contentCard && resp.contentCard.sort((a: any, b: any) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0));
    //   setContentCovid(sorted || []);
    // });
  }, []);

  const diseaseAccuracyMap = {
    high: 'tinggi',
    medium: 'sedang',
    low: 'rendah',
  };

  const diseaseAccuracyDescMap = {
    [diseaseAccuracyMap.high]: 'Kemungkinan Tinggi',
    [diseaseAccuracyMap.medium]: 'Kemungkinan Sedang',
    [diseaseAccuracyMap.low]: 'Kemungkinan Rendah',
  };

  const priority = {
    homecare: 1,
    opd: 2,
  };

  const triagePriority = (triage: any) => {
    const isHomecare = triage === 'Homecare';
    const isOpd = triage === 'OPD' || triage === 'Poli Klinik' || triage === 'UGD';

    if (isHomecare) {
      return priority.homecare;
    } else if (isOpd) {
      return priority.opd;
    }
  };

  // unused at covid as we used static classify
  // const classifyDisease = (score: any) => {
  //   if (score > 0.25) {
  //     return diseaseAccuracyMap.high;
  //   } else if (score > 0.15) {
  //     return diseaseAccuracyMap.medium;
  //   }

  //   return diseaseAccuracyMap.low;
  // };

  const classifyDiseaseLevel = (score: any) => {
    if (score > 0.25) {
      return priority.opd;
    } else if (score > 0.15) {
      return priority.homecare;
    }

    return priority.homecare;
  };

  const convertDisease = (disease: DiseaseType, i: number) => {
    // const classify = classifyDisease(disease.score); unused at covid as we used static classify
    const triage = triagePriority(disease.triage.nameIndo);
    const classifyLevel = classifyDiseaseLevel(disease.score);

    const staticDiseaseClassify = i === 0 ? 'high' : i === 1 ? 'medium' : 'low';

    return {
      id: disease.id,
      name: disease.name,
      score: disease.score,
      triage: triage,
      classify: diseaseAccuracyMap[staticDiseaseClassify],
      classifyLevel: classifyLevel,
      evidenceLevel: diseaseAccuracyDescMap[diseaseAccuracyMap[staticDiseaseClassify]],
      article: disease.url || '#',
      prognosis: disease.prognosis,
      description: disease.description,
    };
  };

  const convertedDisease = (diseases || []).map((disease: any, i: number) => convertDisease(disease, i));
  const highestDisease = convertedDisease.reduce((max: any, next: any) => {
    if (max.classifyLevel === next.classifyLevel) {
      if (max.triage > next.triage) {
        return max;
      } else {
        return next;
      }
    } else if (max.classifyLevel > next.classifyLevel) {
      return max;
    } else if (max.classifyLevel < next.classifyLevel) {
      return next;
    } else {
      return '';
    }
  });

  // const summaryMessage = highestDisease.triage === priority.homecare ? triageMessage.homecare : triageMessage.opd;
  const whatTodoNextPriority = highestDisease.triage === priority.homecare ? 'homecare' : 'opd';
  const showSelfForm = highestDisease.triage === priority.homecare ? false : true;
  const showHotline = highestDisease.triage === priority.homecare && !hotline ? false : true;
  const showRDTForm = props.isValidForRDT && rdtUrl ? true : false;

  return (
    <div className="prixa-container is-top is-full">
      {!isReady ? (
        <div
          className="margin-largeX margin-baseT margin-largeB"
          style={{ height: 295, width: 295, background: '#ccc', borderRadius: '15px' }}
        />
      ) : (
        <SummaryMessage
          showRDTForm={showRDTForm}
          showSelfForm={showSelfForm}
          rdtUrl={rdtUrl}
          formUrl={formUrl}
          sessionId={props.sessionId}
        />
      )}

      <ResultDiseasesList resultDiseases={convertedDisease} isValidForRDT={props.isValidForRDT} />

      <WhatToDoNext priority={whatTodoNextPriority} />

      {showHotline && hotline && <HotlineAccordion hotline={hotline} />}

      <EmailAndSummaryComplaint
        profiles={profiles}
        userDetails={userDetails}
        symptoms={symptoms}
        user={user}
        sessionId={props.sessionId}
        isValidForRDT={props.isValidForRDT}
      />
      {showUserManagement() ||
      (localStorage.getItem('RDTValid') === 'true' && process.env.REACT_APP_ACTIVE === 'covid') ? (
        <div className="margin-large">
          <CtaFeature />
        </div>
      ) : (
        <span />
      )}

      <span
        onClick={() => {
          setModalSurvey(true);
          openSideSheet();
        }}
      >
        <Button size="full" variant="primary">
          Selesai
        </Button>
      </span>

      <Sidesheet
        setModal={setModalSurvey}
        show={modalSurvey}
        title="Survey Layanan"
        content={<SurveySideSheet sessionId={props.sessionId}></SurveySideSheet>}
      ></Sidesheet>
    </div>
  );
};

export default ResultPage;
