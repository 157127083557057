import React, { useState } from 'react';
import { PrecondProps } from './BasicPrecondition';
import { FormLabel, Paragraph, Button, openSideSheet, Link, Sidesheet } from 'prixa-design-kit/dist';
import { RadioNormal } from '../radionormal/RadioNormal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { ListCitiesSideSheet } from './ListCitiesSideSheet';

export const InfectionPrecondition = (props: PrecondProps) => {
  const [showDaftarKota, setShowDaftarKota] = useState<boolean>(false);
  const [contactHistory, setContactHistory] = useState<string | null>(null);
  const [localTransmission, setLocalTransmission] = useState<string | null>(null);

  const thirdSubmit = (event: any) => {
    event.preventDefault();
    const history: any = [];
    if (contactHistory === 'Ya') {
      history.push(
        props.list.find((e: any) => {
          return e.name === 'Contact History';
        }),
      );
    }
    if (localTransmission === 'Ya') {
      history.push(
        props.list.find((e: any) => {
          return e.name === 'Local Transmission';
        }),
      );
    }

    props.setConvoState({ state: '' });
    props.callFunction({
      type: 'Preconditions',
      preconditions: [...props.preconditionData, ...history],
    });
  };

  return (
    <div className="prixa-container">
      <form onSubmit={thirdSubmit}>
        <div className="prixa-title">
          <Paragraph scale="question">Apakah dalam 14 hari terakhir Anda:</Paragraph>
          <div className="row margin-baseB">
            <FormLabel small>Melakukan kontak dengan orang yang positif COVID-19?</FormLabel>
            <RadioNormal
              name="contactHistory"
              text="Ya"
              value="Ya"
              onChange={() => setContactHistory('Ya')}
              selected={contactHistory === 'Ya'}
            />
            <RadioNormal
              name="contactHistory"
              text="Tidak"
              value={0}
              onChange={() => setContactHistory('Tidak')}
              selected={contactHistory === 'Tidak'}
            />
            <RadioNormal
              name="contactHistory"
              text="Tidak Tahu"
              value={2}
              onChange={() => setContactHistory('Tidak Tahu')}
              selected={contactHistory === 'Tidak Tahu'}
            />
          </div>
          <div className="row margin-baseB">
            <FormLabel small>Bepergian/tinggal di kota yang menjadi transmisi lokal virus Corona?</FormLabel>
            <RadioNormal
              name="localTransmission"
              text="Ya"
              value="Ya"
              onChange={() => setLocalTransmission('Ya')}
              selected={localTransmission === 'Ya'}
            />
            <RadioNormal
              name="localTransmission"
              text="Tidak"
              value="Tidak"
              onChange={() => setLocalTransmission('Tidak')}
              selected={localTransmission === 'Tidak'}
            />
          </div>
        </div>
        <span
          onClick={() => {
            setShowDaftarKota(true);
            openSideSheet();
          }}
          data-cy="link-details-localtransmission-cities"
        >
          <Link scale="feedbackLink">
            LIHAT DAFTAR KOTA <FontAwesomeIcon icon={faArrowCircleRight} />
          </Link>
        </span>
        <Sidesheet
          setModal={setShowDaftarKota}
          show={showDaftarKota}
          title="Kota Sebaran Virus Corona"
          content={<ListCitiesSideSheet />}
        ></Sidesheet>
        <div className="prixa-right-button">
          <Button
            className="dav-special"
            type="submit"
            size="option"
            variant="primary"
            disabled={contactHistory === null || localTransmission === null ? true : false}
          >
            Lanjut
          </Button>
        </div>
      </form>
    </div>
  );
};
