import React from 'react';
import { Text, Button, Sidesheet, openSideSheet, Card } from 'prixa-design-kit/dist';
import EmailSideSheet from '../../diagnostic/EmailSideSheet';
import SummarySideSheet from '../../diagnostic/SummarySideSheet';
import { ProfileType, UserDetailsType, UserType, SymptomType } from '.';

interface EmailAndSummaryComplaintProps {
  profiles: Array<ProfileType>;
  userDetails: UserDetailsType;
  user: UserType;
  symptoms: Array<SymptomType>;
  sessionId: string;
  isValidForRDT: boolean;
}

export const EmailAndSummaryComplaint = ({
  profiles,
  userDetails,
  user,
  symptoms,
  sessionId,
  isValidForRDT,
}: EmailAndSummaryComplaintProps) => {
  const [modalSummary, setModalSummary] = React.useState<boolean>(false);
  const [modalEmail, setModalEmail] = React.useState<boolean>(false);

  // const findInProfiles = (name: any) => {
  //   const findElement = profiles.find((element: any) => element.type === name);
  //   if (findElement) {
  //     return findElement.nameIndo;
  //   } else {
  //     return '';
  //   }
  // };

  // const gender = findInProfiles('gender');
  // const age = userDetails.ageYear ? userDetails.ageYear + ' tahun ' : '';
  // const month = userDetails.ageMonth ? userDetails.ageMonth + ' bulan' : '';

  // const ageResult = age + month;

  return (
    <React.Fragment>
      <Card className="prixa-result-content text-center margin-largeX margin-baseT margin-largeB">
        <Text style={{ color: '#4C4F54', paddingTop: '16px' }}>
          <b>Perlu diingat, Hasil Prixa ini bukan pengganti diagnosis medis dokter ya. </b>
        </Text>
        <Text className="margin-baseB" style={{ color: '#4C4F54' }}>
          Segera konsultasi dengan dokter jika keluhan memburuk atau terdapat keluhan lain yang Anda rasakan.
        </Text>
        <span
          onClick={() => {
            setModalSummary(true);
            openSideSheet();
          }}
        >
          <Button
            size="large"
            variant="outline"
            color="white"
            className="dav-special margin-smallB"
            width="220px"
            style={{ color: '#0146AB', borderColor: '#0146AB' }}
          >
            Lihat Rangkuman Keluhan
          </Button>
        </span>
        <span
          onClick={() => {
            setModalEmail(true);
            openSideSheet();
          }}
        >
          <Button
            size="large"
            className="dav-special"
            variant="outline"
            color="white"
            width="220px"
            style={{ color: '#0146AB', borderColor: '#0146AB', marginBottom: '24px' }}
          >
            Kirim Hasil Prixa ke Email
          </Button>
        </span>
      </Card>

      <Sidesheet
        setModal={setModalSummary}
        show={modalSummary}
        title="Rangkuman Keluhan"
        content={
          <SummarySideSheet
            user={user}
            profiles={profiles}
            symptoms={symptoms}
            userDetails={userDetails}
            isValidForRDT={isValidForRDT}
          ></SummarySideSheet>
        }
      ></Sidesheet>

      <Sidesheet
        setModal={setModalEmail}
        show={modalEmail}
        title="Kirim Hasil Prixa"
        content={<EmailSideSheet sessionId={sessionId}></EmailSideSheet>}
      ></Sidesheet>
    </React.Fragment>
  );
};

export default EmailAndSummaryComplaint;
