import React from 'react';
import { Accordion, Text } from 'prixa-design-kit/dist';

const whatToDoNextText = {
  homecare: [
    {
      title: 'Isolasi mandiri di rumah selama 7 hari',
      content: (
        <span>
          <b>Jaga jarak setidaknya 2 meter</b> dari anggota keluarga, kerabat, atau orang lain di sekitar Anda untuk
          mengurangi risiko penularan.
        </span>
      ),
    },
    {
      title: 'Gunakan masker',
      content: 'Selama terdapat keluhan untuk mencegah penularan pastikan Anda terus menggunakan masker.',
    },
    {
      title: 'Ikuti etika batuk/bersin yang benar',
      content:
        'Tutup mulut dan hidung dengan tisu atau lengan bagian dalam, lalu buang tisu yang telah digunakan ke tempat sampah.',
    },
    {
      title: 'Cuci tangan dengan air dan sabun',
      content: (
        <span>
          Lakukan dengan air mengalir selama minimal <b>20 detik</b>. Jika tidak tersedia air dan sabun, gunakan hand
          sanitizer yang mengandung setidaknya 70% alkohol.
        </span>
      ),
    },
    {
      title: 'Konsumsi paracetamol 500mg jika demam',
      content: (
        <span>
          Lakukan <b>setiap 8 jam</b> untuk meredakan demam.
        </span>
      ),
    },
  ],
  opd: [
    {
      title: 'Isolasi mandiri di rumah selama 14 hari',
      content: (
        <span>
          Untuk mengurangi risiko penularan lebih lanjut, berdiam di ruangan terpisah dari anggota keluarga, kerabat,
          atau orang lain di sekitar Anda. Apabila berdiam di ruangan terpisah tidak memungkinkan,
          <b>jaga jarak setidaknya 2 meter</b> dari anggota keluarga, kerabat, atau orang lain.
        </span>
      ),
    },
    {
      title: 'Gunakan masker',
      content: 'Selama terdapat keluhan untuk mencegah penularan pastikan Anda terus menggunakan masker.',
    },
    {
      title: 'Ikuti etika batuk/bersin yang benar',
      content:
        'Tutup mulut dan hidung dengan tisu atau lengan bagian dalam, lalu buang tisu yang telah digunakan ke tempat sampah.',
    },
    {
      title: 'Cuci tangan dengan air dan sabun',
      content: (
        <span>
          Lakukan dengan air mengalir selama minimal <b>20 detik</b>. Jika tidak tersedia air dan sabun, gunakan hand
          sanitizer yang mengandung setidaknya 70% alkohol.
        </span>
      ),
    },
    {
      title: 'Konsumsi paracetamol 500mg jika demam',
      content: (
        <span>
          Lakukan <b>setiap 8 jam</b> untuk meredakan demam.
        </span>
      ),
    },
  ],
};

interface WhatToDoNextProps {
  priority: 'homecare' | 'opd';
}
export const WhatToDoNext = ({ priority }: WhatToDoNextProps) => {
  return (
    <React.Fragment>
      <div className="text-center margin-largeX margin-baseY margin-largeY">
        <Text scale="question">Penanganan Mandiri</Text>
      </div>

      {whatToDoNextText[priority].map(({ title, content }, i) => (
        <Accordion key={i} title={title} className="padding-largeX padding-tinyB">
          <Text scale="content">{content}</Text>
        </Accordion>
      ))}
    </React.Fragment>
  );
};

export default WhatToDoNext;
