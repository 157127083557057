import React, { useContext, useState, useEffect, useCallback } from 'react';
import { FormLabel, Dropdown, DropdownItem, Image, Button, Link } from 'prixa-design-kit/dist';
import { BookingContext } from './booking-page/BookingContext';
import { getAPI } from '../../api/api-method';
import { BOOKING_API } from '../../api/api-url';
import { LoadPage } from '../diagnostic/LoadPage';
import { SpecialityType } from './landing-page/SpecialitiesSection';
import { useHistory } from 'react-router-dom';

interface FilterDetailType {
  id: string;
  name: string;
}
export interface FilterDataType {
  area: FilterDetailType;
  hospitals: FilterDetailType;
  specialities: FilterDetailType;
}

const baseIconURL = `${process.env.REACT_APP_ASSET_URL}/Appointment Booking/`;

export const FilterPage = ({ setHeader, setMenu }: any) => {
  const { filterData, setFilterData, allSpecialities, setAllSpecialities } = useContext(BookingContext);
  const [areaOptions, setAreaOptions] = useState([]);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [areaText, setareaText] = useState(filterData.area.name);
  const [areaValue, setareaValue] = useState(filterData.area.id);
  const [hospitalText, sethospitalText] = useState(filterData.hospitals.name);
  const [hospitalValue, sethospitalValue] = useState(filterData.hospitals.id);
  const [specialitiesValue, setspecialitiesValue] = useState(filterData.specialities.id);
  const [specialitiesText, setspecialitiesText] = useState(filterData.specialities.name);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeader('Filter');
    setMenu(false);
  }, [setMenu, setHeader]);

  const selectArea = useCallback((props: DropdownItem) => {
    getAPI(`${BOOKING_API.HOSPITALS}?param.areaId=${props.value}`).then((resp: any) => {
      setHospitalOptions(resp.data ? resp.data.map((hspitl: any) => ({ value: hspitl.id, text: hspitl.name })) : []);
      setareaText(props.text!);
      setareaValue(props.value);
      sethospitalText('');
      sethospitalValue('');
    });
  }, []);

  const selectHospital = useCallback((props: DropdownItem) => {
    sethospitalText(props.text!);
    sethospitalValue(props.value);
  }, []);

  const selectSpecialities = useCallback(
    (props: DropdownItem) => {
      if (specialitiesValue !== props.value) {
        setspecialitiesText(props.text!);
        setspecialitiesValue(props.value);
      } else {
        setspecialitiesText('');
        setspecialitiesValue('');
      }
    },
    [specialitiesValue],
  );

  useEffect(() => {
    if (filterData.area.id) {
      selectArea({ text: filterData.area.name, value: filterData.area.id });
    }
  }, [filterData.area, selectArea]);

  useEffect(() => {
    if (
      filterData.hospitals.name &&
      hospitalOptions.find((hsptl: DropdownItem) => hsptl.value === filterData.hospitals.id)
    ) {
      setTimeout(() => sethospitalText(filterData.hospitals.name), 200);
    }
  }, [hospitalOptions, filterData.hospitals]);

  useEffect(() => {
    getAPI(BOOKING_API.AREAS).then((resp: any) => {
      setAreaOptions(resp.data.map((area: any) => ({ value: area.id, text: area.name })));
    });

    Promise.all([getAPI(BOOKING_API.SPECIALITIES), getAPI(BOOKING_API.SPECIALITIES + '?page=2')]).then(
      (allResp: any) => {
        const merge = allResp[0].data.concat(allResp[1].data);
        setAllSpecialities(merge);
      },
    );
  }, [setAllSpecialities]);

  const setFilterDataHandler = () => {
    setFilterData({
      area: { id: areaValue, name: areaText },
      hospitals: { id: hospitalValue, name: hospitalText },
      specialities: { id: specialitiesValue, name: specialitiesText },
    });
    history.push('/search-doctor');
  };

  const resetFilterHandler = () => {
    setareaText('');
    sethospitalText('');
    setAreaOptions(areaOptions);
    setAreaOptions(areaOptions);
    setHospitalOptions(hospitalOptions);
    setspecialitiesValue('');
    setFilterData({ area: { id: '', name: '' }, hospitals: { id: '', name: '' }, specialities: { id: '', name: '' } });
  };

  if (areaOptions.length === 0 || !allSpecialities) return <LoadPage />;

  return (
    <div className="prixa-container is-top is-full">
      <div className="margin-largeX">
        <div className="margin-baseT padding-baseB" style={{ borderBottom: '1px solid #cccccc' }}>
          <FormLabel errors={false}>Area</FormLabel>
          <Dropdown options={areaOptions} placeholder="Pilih Area" onSelect={selectArea} value={areaText} />
        </div>
        <div className="margin-baseT padding-baseB" style={{ borderBottom: '1px solid #cccccc' }}>
          <FormLabel errors={false}>Rumah Sakit</FormLabel>
          <Dropdown
            options={hospitalOptions}
            placeholder="Pilih Rumah Sakit"
            onSelect={selectHospital}
            value={hospitalText}
            disabled={!areaText}
          />
        </div>
        <div className="margin-baseT">
          <FormLabel errors={false}>Spesialisasi</FormLabel>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {allSpecialities.map(({ id, nameIndo }: SpecialityType, i: number) => (
              <div
                key={i}
                className={`text-center margin-smallB padding-microX`}
                style={{
                  fontSize: 12,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '30%',
                  wordWrap: 'break-word',
                  cursor: 'pointer',
                }}
                onClick={() => selectSpecialities({ value: id, text: nameIndo })}
              >
                <Image
                  avatar
                  size="tiny"
                  style={{
                    height: '64px',
                    width: '64px',
                    boxShadow: specialitiesValue === id ? '0 1px 1px 0 #0046AB, 0 0 0 2px #0046AB' : 'none',
                    marginBottom: '8px',
                  }}
                  src={`${baseIconURL}Icon ${nameIndo}-min.png`}
                />
                <span className={specialitiesValue === id ? 'color-primary text-bold' : 'color-default'}>
                  {nameIndo}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center margin-largeY">
          <Button size="base" variant="primary" onClick={setFilterDataHandler} className="margin-smallB">
            Cari
          </Button>
          <Link onClick={resetFilterHandler} scale="headerTitle">
            Atur Ulang
          </Link>
        </div>
      </div>
    </div>
  );
};
