import React, { useEffect, useState } from 'react';
import { Text, Button, Icon } from 'prixa-design-kit/dist';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { getAPI } from '../../api/api-method';
import { USER_API } from '../../api/api-url';

interface ProfileList {
  type: any;
  title: string;
  link: string;
}

const ProfilePage = () => {
  const profileList: ProfileList[] = [
    { type: 'faUserAlt', title: 'Data Diri', link: '/personal-information' },
    { type: 'faHeartbeat', title: 'Informasi Kesehatan', link: '/precondition-info' },
    { type: 'faCalendar', title: 'Riwayat Keluhan', link: '/diagnosis-history' },
  ];
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [url, setURL] = useState('');
  const [loadUrl, setLoadUrl] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      const profileDataLocalStorage = localStorage.getItem('profileData');
      const profileData = JSON.parse(String(profileDataLocalStorage));
      if (profileData !== null) {
        setName(profileData.name);
        setEmail(profileData.email);
        // setURL(profileData.avatarURL || 'https://picsum.photos/200');
        setURL('https://picsum.photos/200');
        setTimeout(() => {
          setLoadUrl(false);
        }, 1000);
      }
    }, 500);
    if (name !== undefined) {
      clearInterval(timer);
    }
  });

  return (
    <div className="prixa-container is-top">
      <div className="text-center">
        {loadUrl ? (
          <Icon style={{ height: '64px', color: '#0146AB', margin: 'auto' }} type="faCircleNotch" spin />
        ) : (
          <img
            loading="lazy"
            alt="User Profile"
            height="64px"
            width="64px"
            src={url}
            style={{
              borderRadius: '100%',
              height: '64px',
              width: '64px',
              objectFit: 'cover',
              border: '4px solid #aaaaaa',
              marginBottom: '8px',
            }}
          />
        )}
        <br />
        <Text scale="content" style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>
          {name}
        </Text>
        <br />
        <Text scale="content" style={{ color: '#AAAAAA' }}>
          {email}
        </Text>
      </div>
      <div className="prixa-profile-list margin-baseY margin-largeB">
        {profileList.map((item: ProfileList, key: number) => {
          return <ProfileList key={key} item={item} />;
        })}
      </div>
      <ButtonSignOut />
    </div>
  );
};

const ProfileList = ({ item }: any) => {
  const history = useHistory();

  const goToNavigation = () => {
    history.push(item.link);
  };

  return (
    <div onClick={() => goToNavigation()} className="list">
      <div className="item">
        <Icon className="margin-smallR" style={{ color: '#0146ab' }} type={item.type} />
        <span className="title">{item.title}</span>
      </div>
      <Icon style={{ color: '#4C4F54' }} type="faChevronRight" />
    </div>
  );
};

const ButtonSignOut = () => {
  const signOut = () => {
    getAPI(USER_API.LOGOUT).then(() => {
      localStorage.removeItem('loginToken');
      localStorage.removeItem('profileData');
      localStorage.removeItem('personID');
      localStorage.removeItem('isVerified');
      localStorage.removeItem('profileData');
      window.location.href = '/continue';
    });
  };

  return (
    <Button
      onClick={() => signOut()}
      size="xLarge"
      className="dav-special"
      variant="outline"
      style={{ color: '#FF6E5D', borderColor: '#FF6E5D', width: '100%' }}
    >
      <Icon type="faSignOutAlt" style={{ color: '#FF6E5D', margin: 'auto', paddingLeft: '100px' }}>
        <Text style={{ fontSize: '16px', color: '#FF6E5D', margin: 'auto', paddingRight: '100px' }}>
          {' '}
          <b>Keluar</b>
        </Text>
      </Icon>
    </Button>
  );
};

export { ProfilePage };
