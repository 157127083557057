import React, { useState, useEffect } from 'react';
import { SetContainerProps } from '../../App';
import {
  Paragraph,
  OptionsButton,
  InputText,
  Sidesheet,
  openSideSheet,
  Link,
  Icon,
  Button,
  Toast,
} from 'prixa-design-kit/dist';
import StatusList from '../../../components/statuslist/StatusList';
import StatusSideSheet from '../../diagnostic/StatusSideSheet';
import { LoadPage } from '../../diagnostic/LoadPage';
import { handleSubmit, getPreconditionsList } from './helpers';

export interface PrecondListInt {
  id?: string;
  type?: string;
  nameIndo?: string;
  preconditionsDescription?: string;
  preconditionsDescriptionCopy?: string;
  ageMonth?: number;
  ageYear?: number;
  weight?: number;
  height?: number;
  name?: string;
}

const HealthInformation: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  const [modal, setModal] = React.useState(false);
  const [gender, setGender] = useState<PrecondListInt | undefined>({});
  const [genderList, setGenderList] = useState<PrecondListInt[]>([]);
  const [ageMonth, setAgeMonth] = useState(0);
  const [ageYear, setAgeYear] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [errHeight, setErrHeight] = useState(false);
  const [errWeight, setErrWeight] = useState(false);
  const [errGender, setErrGender] = useState(true);
  const [darahTinggi, setDarahTinggi] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [merokok, setMerokok] = useState(false);
  const [minumAlkohol, setMinumAlkohol] = useState(false);
  const [menopause, setMenopause] = useState(false);
  const [hamil, setHamil] = useState(false);
  const [kanker, setKanker] = useState(false);
  const [sakitJantung, setSakitJantung] = useState(false);
  const [gagalGinjal, setGagalGinjal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadButton, setIsLoadButton] = useState(false);
  const [isErrorMsg, setError] = useState('');
  const [descriptivePrecondition, setDescriptivePrecondition] = useState<PrecondListInt[]>([]);
  const [preconditionData, setPreconditionData] = useState<PrecondListInt[]>([]);

  /*eslint-disable */
  useEffect(() => {
    props.setHeader('Informasi Kesehatan');

    return () => {
      props.setHeader('');
      handleEditMode(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getPreconditionsList(
      setAgeYear,
      setAgeMonth,
      setError,
      setLoading,
      setPreconditionData,
      descriptivePrecondition,
      setGenderList,
      setDescriptivePrecondition,
      setGender,
      setWeight,
      setHeight,
      setDarahTinggi,
      setDiabetes,
      setMerokok,
      setMinumAlkohol,
      setKanker,
      setSakitJantung,
      setGagalGinjal,
      setHamil,
      setMenopause,
    );
  }, []);
  /*eslint-enable */

  useEffect(() => {
    if (height > 0 && height < 300) {
      setErrHeight(false);
    } else {
      setErrHeight(true);
    }
  }, [height]);

  useEffect(() => {
    if (weight > 0 && weight < 500) {
      setErrWeight(false);
    } else {
      setErrWeight(true);
    }
  }, [weight]);

  useEffect(() => {
    if (JSON.stringify(gender) === '{}') {
      setErrGender(true);
    } else {
      setErrGender(false);
    }
  }, [gender]);

  let isFemale = gender?.name === 'Female' ? gender : {};

  const age = ageYear;
  const isCanDrinkAndSmoke = !(age < 17);
  let isCanMenopause = age >= 35 && gender?.name === 'Female';
  let isCanPregnant = !(age < 17 || age > 65) && gender?.name === 'Female';
  const showPregnancy = isFemale && isFemale.id && isCanPregnant && !menopause;
  const showMenopause = isFemale && isFemale.id && isCanMenopause && !hamil;
  const showDrinkAndSmoke = isCanDrinkAndSmoke;
  const disableStatusList = !props.editMode;

  const statusListProps = {
    darahTinggi,
    diabetes,
    merokok,
    minumAlkohol,
    menopause,
    hamil,
    kanker,
    sakitJantung,
    gagalGinjal,
    setDarahTinggi,
    setDiabetes,
    setMerokok,
    setMinumAlkohol,
    setMenopause,
    setHamil,
    setKanker,
    setSakitJantung,
    setGagalGinjal,
    showPregnancy,
    showMenopause,
    showDrinkAndSmoke,
    disableStatusList,
  };

  const handleSetGender = (gender: PrecondListInt): void => {
    setGender(gender);
    isFemale = gender.name === 'Female' ? gender : {};
    isCanMenopause = age >= 35 && gender.name === 'Female';
    isCanPregnant = !(age < 17 || age > 65) && gender.name === 'Female';
  };

  const handleEditMode = (mode: boolean): void => {
    if (props.setEditMode) props.setEditMode(mode);
  };

  if (isLoading) return <LoadPage />;

  return (
    <React.Fragment>
      <div className="prixa-container is-top" style={{ paddingBottom: 0 }}>
        <form>
          <div className="prixa-title">
            <div className="row margin-smallB">
              <div style={{ display: 'flex' }}>
                <Paragraph scale="pagesubtitle" className="prixa-status-category-title" style={{ flex: 1 }}>
                  JENIS KELAMIN
                </Paragraph>
              </div>
              <div style={{ marginBottom: 16, display: 'flex' }}>
                <OptionsButton
                  data={genderList}
                  setData={handleSetGender}
                  selected={gender}
                  disable={!props.editMode}
                />
              </div>
              <div className="row flex-inline" style={{ marginBottom: 24 }}>
                <div className="margin-baseR">
                  <Paragraph scale="pagesubtitle" className="prixa-status-category-title">
                    TINGGI (CM)
                  </Paragraph>
                  <InputText
                    type="number"
                    max={300}
                    setData={setHeight}
                    value={height}
                    placeholder="170"
                    small
                    errors={props.editMode && errHeight}
                    disabled={!props.editMode}
                  />
                </div>
                <div className="margin-baseR">
                  <Paragraph scale="pagesubtitle" className="prixa-status-category-title">
                    BERAT (KG)
                  </Paragraph>
                  <InputText
                    type="number"
                    max={500}
                    setData={setWeight}
                    value={weight}
                    placeholder="65"
                    small
                    errors={props.editMode && errWeight}
                    disabled={!props.editMode}
                  />
                </div>
              </div>
              <StatusList statusListProps={statusListProps} />
              <span
                onClick={(): void => {
                  setModal(true);
                  openSideSheet();
                }}
              >
                <Link scale="feedbackLink" style={{ display: 'flex', alignItems: 'center' }}>
                  LIHAT PENJELASAN
                  <Icon type="faArrowCircleRight" className="margin-tinyL" color="secondary" />
                </Link>
              </span>
              <Sidesheet
                setModal={setModal}
                show={modal}
                className="prixa-sidesheet"
                title="Status Kesehatan & Kebiasaan"
                content={
                  <StatusSideSheet
                    isFemale={isFemale}
                    // isCanPregnant={isCanPregnant}
                    // isCanMenopause={isCanMenopause}
                    isCanDrinkAndSmoke={isCanDrinkAndSmoke}
                    data={descriptivePrecondition}
                    showMenopause={showMenopause}
                    showPregnancy={showPregnancy}
                  />
                }
              />
            </div>
          </div>
        </form>
      </div>
      {props.editMode && (
        <div className="prixa-footer-button">
          <Button
            type="button"
            variant="primary"
            size="full"
            onClick={(): Promise<void> =>
              handleSubmit(
                setIsLoadButton,
                gender,
                height,
                weight,
                ageMonth,
                ageYear,
                preconditionData,
                darahTinggi,
                diabetes,
                merokok,
                minumAlkohol,
                kanker,
                sakitJantung,
                gagalGinjal,
                hamil,
                menopause,
                setError,
                handleEditMode,
              )
            }
            disabled={errHeight || errWeight || errGender}
            spinner={isLoadButton}
          >
            Simpan
          </Button>
        </div>
      )}
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </React.Fragment>
  );
};

export default HealthInformation;
