import React from 'react';
import { Paragraph, Button, Text } from 'prixa-design-kit/dist';
import TextArea from '../../components/textarea/TextArea';
import { postAPI } from '../../api/api-method';
import { DIAGNOSTIC_API } from '../../api/api-url';
import { getAppID, getPartnerID } from '../../utils/constant';
import { UseTracking } from '../../utils/useTracking';
import { currentState } from '../../api/api-utils';

const imgFeedback = `${process.env.REACT_APP_ASSET_URL}/illustration/feedback.png`;

export interface Option {
  text: string;
  value: number;
  variant: 'default' | 'outline' | 'primary' | 'disabled' | 'secondary' | 'success' | undefined;
}

const pages = {
  SURVEY_PAGE: 'Survey Page',
  FEEDBACK_PAGE: 'Feedback Page',
  SURVEY_FINISH_PAGE: 'Survey Finish Page',
};

const SurveySideSheet = (props: any) => {
  const [surveyPage, setSurveyPage] = React.useState(pages.SURVEY_PAGE);
  const [surveyData, setSurveyData] = React.useState({
    applicationId: getAppID,
    feedback: null,
    partnerId: getPartnerID,
    rating: null,
    sessionId: props.sessionId,
  });

  if (surveyPage === pages.SURVEY_PAGE) {
    return <SurveyPage surveyData={surveyData} setSurveyData={setSurveyData} setPage={setSurveyPage}></SurveyPage>;
  } else if (surveyPage === pages.FEEDBACK_PAGE) {
    return <FeedbackPage surveyData={surveyData} setSurveyData={setSurveyData} setPage={setSurveyPage}></FeedbackPage>;
  } else if (surveyPage === pages.SURVEY_FINISH_PAGE) {
    return <SurveyFinishPage sessionId={props.sessionId}></SurveyFinishPage>;
  }
  return <div></div>;
};

const SurveyPage = (props: any) => {
  const options: Option[] = [
    { text: 'Sangat terbantu', value: 4, variant: 'secondary' },
    { text: 'Terbantu', value: 3, variant: 'secondary' },
    { text: 'Kurang terbantu', value: 2, variant: 'secondary' },
    { text: 'Tidak terbantu', value: 1, variant: 'secondary' },
  ];
  return (
    <div className="prixa-container">
      <div className="prixa-body">
        <div className="prixa-title">
          <Paragraph scale="question">Seberapa terbantu Anda dengan layanan Prixa?</Paragraph>
        </div>
      </div>
      <div className="prixa-right-button">
        {options.map((option: Option, i) => {
          return (
            <div key={i}>
              <span
                onClick={() => {
                  UseTracking({ event: `Survey ${option.text} selected`, properties: { sessionId: props.sessionId } });
                  const veryHelpfulOrHelpful = option.value === 4 || option.value === 3;
                  props.surveyData.rating = option.value;
                  if (veryHelpfulOrHelpful) {
                    localStorage.removeItem('sesId');
                    postAPI(DIAGNOSTIC_API.SEND_SURVEY, props.surveyData).then(async (res: any) => {
                      props.setPage(pages.SURVEY_FINISH_PAGE);
                    });
                  } else {
                    props.setPage(pages.FEEDBACK_PAGE);
                  }
                }}
              >
                <Button size="option" className="dav-special" variant={option.variant}>
                  {option.text}
                </Button>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FeedbackPage = (props: any) => {
  const [feedback, setFeedback] = React.useState(null);

  return (
    <div className="prixa-container">
      <div className="prixa-body">
        <div className="prixa-title">
          <Paragraph scale="question">Apa yang menurut Anda perlu diperbaiki di Prixa?</Paragraph>
        </div>
        <TextArea setData={setFeedback} props="Silahkan tulis feedback Anda"></TextArea>
      </div>
      <div className="prixa-right-button margin-largeT">
        <span
          onClick={() => {
            localStorage.removeItem('sesId');
            props.surveyData.feedback = feedback;
            postAPI(DIAGNOSTIC_API.SEND_SURVEY, props.surveyData).then(async (res: any) => {
              props.setPage(pages.SURVEY_FINISH_PAGE);
            });
            localStorage.removeItem('sesId');
          }}
        >
          <Button size="option" className="dav-special" variant="primary">
            Kirim
          </Button>
        </span>
      </div>
    </div>
  );
};

const SurveyFinishPage = ({ sessionId }: { sessionId: string }) => {
  return (
    <div className="prixa-container">
      <div className="prixa-body">
        <div className="prixa-question-image">
          <img loading="lazy" width="240px" alt="Prixa Feedback" src={imgFeedback} />
        </div>
        <div className="prixa-title">
          <Paragraph scale="question">Terima kasih untuk jawaban Anda.</Paragraph>
          <Text scale="content" className="prixa-title-sub">
            Saran Anda akan membantu Prixa untuk dapat memberi layanan yang lebih baik lagi.
          </Text>
        </div>
      </div>
      <div className="prixa-right-button">
        <Button
          onClick={() => {
            UseTracking({ event: `Prixa sekarang ended`, properties: { sessionId, state: currentState } });
            window.location.reload(false);
          }}
          className="dav-special"
          size="option"
          variant="primary"
        >
          Tutup
        </Button>
      </div>
    </div>
  );
};

export default SurveySideSheet;
