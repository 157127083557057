import React from 'react';
import Routes from '../routes/routes';

export interface SetContainerProps {
  setHeader: (title?: string, subtitle?: string) => void;
  setPercentage: (percent?: number) => void;
  setError: (error?: string) => void;
  setMenu?: (menu?: boolean) => void;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const App: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  return (
    <Routes
      setHeader={props.setHeader}
      setPercentage={props.setPercentage}
      setError={props.setError}
      setMenu={props.setMenu}
      editMode={props.editMode}
      setEditMode={props.setEditMode}
    />
  );
};

export default App;
