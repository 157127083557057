import { postAPI } from '../api/api-method';
import { ANALYTICS_API } from '../api/api-url';

interface TRACKTYPE {
  event: string;
  properties: object;
}

export const UseTracking = (props: TRACKTYPE) => {
  const data = {
    event: props.event,
    properties: props.properties,
    context: {
      locale: window.navigator.language,
      page: {
        path: window.location.pathname,
        search: window.location.search,
      },
    },
    integration: {
      ALL: 'false',
    },
    sentAt: new Date().toISOString(),
  };
  postAPI(ANALYTICS_API.TRACK, data);
};

export default UseTracking;
