import React from 'react';
import '../cta-feature/ctaFeature.scss';
import { Text } from 'prixa-design-kit/dist';
import { showTelemedFeature, showBookingeature, showLabFeature, showPharmacyFeature } from '../../utils/constant';
import UseTracking from '../../utils/useTracking';
import { sessionId, currentState } from '../../api/api-utils';

const diagnoseEngineImg = `${process.env.REACT_APP_ASSET_URL}/home/Menu Sistem Periksa.png`;
const consultationViaChatImg = `${process.env.REACT_APP_ASSET_URL}/home/Menu Konsultasi.png`;
const doctorAppointmentImg = `${process.env.REACT_APP_ASSET_URL}/home/Menu Janji Temu.png`;
const drugDeliveryImg = `${process.env.REACT_APP_ASSET_URL}/home/Menu Antar Farmasi.png`;
const labTestImg = `${process.env.REACT_APP_ASSET_URL}/home/Menu Tes Lab.png`;

interface CtaFeatureProps {
  isTitle?: boolean;
  isShowDiagnonseMenu?: boolean;
}

const CtaFeature = ({ isTitle = true, isShowDiagnonseMenu = false }: CtaFeatureProps) => {
  const CTAContent = [
    {
      text: 'Konsultasi via Chat',
      enabled: showTelemedFeature() ? true : false,
      link:
        localStorage.getItem('telemedicineSDKURL') === ''
          ? '/initiate'
          : `${localStorage.getItem('telemedicineSDKURL')}?sessionID=${sessionId}`,
      newTab: false,
      img: consultationViaChatImg,
    },
    {
      text: 'Janji Temu Dokter',
      enabled: showBookingeature() ? true : false,
      link: '/booking',
      newTab: false,
      img: doctorAppointmentImg,
    },
    {
      text: 'Pesan Antar Farmasi',
      enabled: showPharmacyFeature() ? true : false,
      link: 'https://www.farmaku.com/',
      newTab: true,
      img: drugDeliveryImg,
    },
    {
      text: 'Tes Lab',
      enabled: showLabFeature() ? true : false,
      link: 'https://www.triasse.com/',
      newTab: true,
      img: labTestImg,
    },
  ];

  return (
    <div className="prixa-container text-center">
      <CTATitle isTitle={isTitle} />
      <CTADiagnose isShowDiagnonseMenu={isShowDiagnonseMenu} />
      {CTAContent.map((data, i) => {
        if ((i + 1) % 2 === 1) {
          return (
            <div className="prixa-cta" key={i}>
              <CTAContentBlocks
                text={data.text}
                enabled={data.enabled}
                link={data.link}
                newTab={data.newTab}
                img={data.img}
              />
              <CTAContentBlocks
                text={CTAContent[i + 1].text}
                enabled={CTAContent[i + 1].enabled}
                link={CTAContent[i + 1].link}
                newTab={CTAContent[i + 1].newTab}
                img={CTAContent[i + 1].img}
              />
            </div>
          );
        } else {
          return <span key={i} />;
        }
      })}
    </div>
  );
};

const CTAContentBlocks = ({ text, enabled, link, newTab, img }: any) => {
  const imagePlate = (
    <React.Fragment>
      <img loading="lazy" width="140px" height="140px" alt={text} src={img} />
      {enabled ? (
        <span />
      ) : (
        <React.Fragment>
          <div className="prixa-cta-soon"></div>
          <span className="coming-soon">Segera Hadir</span>
        </React.Fragment>
      )}
      <div className="prixa-cta-title">
        <span>{text}</span>
      </div>
    </React.Fragment>
  );
  if (enabled) {
    return (
      <a
        target={newTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        data-cy={text.replace(/\s+/g, '-').toLowerCase()}
        href={link}
        className="cta"
        onClick={() => {
          UseTracking({ event: `${text} clicked`, properties: { sessionId, state: currentState } });
        }}
      >
        {imagePlate}
      </a>
    );
  } else {
    return <div className="cta">{imagePlate}</div>;
  }
};

const CTATitle = ({ isTitle }: any) => {
  if (isTitle) {
    return (
      <div className="margin-largeB">
        <Text scale="headerTitle">
          Perlu penanganan lebih lanjut untuk keluhan Anda? <br /> Prixa siap membantu.
        </Text>
      </div>
    );
  } else return null;
};

const CTADiagnose = ({ isShowDiagnonseMenu }: any) => {
  if (isShowDiagnonseMenu) {
    return (
      <div className="prixa-cta">
        <a
          href="/"
          className="cta"
          onClick={() => {
            UseTracking({ event: `Prixa Sekarang clicked`, properties: { sessionId, state: currentState } });
          }}
        >
          <img loading="lazy" width="100%" alt="Diagnose Engine" src={diagnoseEngineImg} />
          <div className="prixa-cta-title">
            <span>Periksa Gejala</span>
          </div>
        </a>
      </div>
    );
  } else return null;
};

export default CtaFeature;
