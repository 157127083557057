import React, { useEffect, Fragment } from 'react';
import { Button, Paragraph, Box, Card, Text } from 'prixa-design-kit/dist';
import { getAPI } from '../../../api/api-method';
import { DIAGNOSTIC_API } from '../../../api/api-url';
import { ContentCardType } from '../result-page';
import { Link as LinkRoute, Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import '../intro-page/introPage.scss';

// const landing = `${process.env.REACT_APP_ASSET_URL}/covid/Landing%20Page%20Corona%20white.png`;
const landing = `${process.env.REACT_APP_ASSET_URL}/covid/On%20Boarding%20-%201.png`;

const IntroPage: React.FC = () => {
  const [contentCovid, setContentCovid] = React.useState([]);
  const [contentPrixa, setContentPrixa] = React.useState([]);
  const storageSessionId = localStorage.getItem('sesId');

  /* eslint-disable */
  useEffect(() => {
    if (!storageSessionId) {
      getAPI(DIAGNOSTIC_API.ALL_CONTENT).then((res: any) => {
        const tempCovid = res.contentCard && res.contentCard.filter((covid: ContentCardType) => covid.type === 'covid');
        const tempPrixa = res.contentCard && res.contentCard.filter((prixa: ContentCardType) => prixa.type === 'prixa');
        tempCovid && tempCovid.sort((a: any, b: any) => (a.sort > b.sort ? 1 : -1));
        tempPrixa && tempPrixa.sort((a: any, b: any) => (a.sort > b.sort ? 1 : -1));
        setContentCovid(tempCovid || []);
        setContentPrixa(tempPrixa || []);
      });
    }
  }, []);
  /* eslint-enable */

  if (storageSessionId) {
    return <Redirect to="/conversation" />;
  }

  return (
    <div className="prixa-container is-top" style={{ padding: '40px 0px 0px 0px' }}>
      <section id="first-section">
        <div className="prixa-question-image">
          <img
            loading="lazy"
            alt="Covid landing"
            src={landing}
            style={{ width: '280px', height: '220px' }}
            data-cy="covid-image-landing"
          />
        </div>
        <Paragraph scale="heroTitle" className="text-center" style={{ width: '275px', margin: 'auto auto 24px' }}>
          Lebih tenang dengan periksa gejala risiko COVID-19 di Prixa
        </Paragraph>
        <div className="text-center" style={{ marginBottom: '85px' }}>
          <LinkRoute to="/consent">
            <Button
              className="dav-special"
              size="option"
              variant="primary"
              style={{ fontSize: '24px', padding: '16px 39px' }}
              data-cy="button-prixa-sekarang"
            >
              Prixa Sekarang
            </Button>
          </LinkRoute>
        </div>
      </section>

      <Box
        variant="default"
        id="second-section"
        style={{ borderRadius: '0', width: '100%', padding: '40px 0px' }}
        className="margin-largeB"
      >
        <Paragraph scale="heroTitle" className="text-center" color="light" fontSize="24px" lineHeight="28px">
          Kenapa Prixa?
        </Paragraph>
        <div className="padding-tinyX flex-inline scrollbar">
          {contentPrixa.length > 0 ? (
            contentPrixa.map((data: ContentCardType) => (
              <Card style={{ width: '220px', margin: '0 4px' }} key={data.id}>
                <div className="text-center margin-baseB" style={{ width: '100%' }}>
                  <img
                    loading="lazy"
                    alt="Covid landing"
                    src={data.imageURL}
                    style={{ width: '192px', height: '160px' }}
                  />
                </div>
                <Paragraph scale="headerTitle" className="text-left" style={{ maxWidth: '140px' }}>
                  {data.title || '-'}
                </Paragraph>
              </Card>
            ))
          ) : (
            <PrixaSectionSkeleton />
          )}
        </div>
      </Box>
      <section id="last-section" className="margin-largeB">
        <Paragraph
          scale="heroTitle"
          className="text-center"
          fontSize="24px"
          lineHeight="28px"
          style={{ width: '275px', margin: 'auto auto 40px' }}
        >
          Ketahui Tindakan Terkait COVID-19
        </Paragraph>
        {contentCovid.length > 0 ? (
          contentCovid.map((data: ContentCardType) => (
            <Card style={{ display: 'flex', margin: '0px 24px 24px' }} key={data.id} className="padding-base">
              <div className="text-center margin-baseB" style={{ height: '100%', margin: 'auto 24px auto 0px' }}>
                <img loading="lazy" alt="Covid landing" src={data.imageURL} style={{ width: '80px', height: 'auto' }} />
              </div>
              <div style={{ width: '100%' }}>
                <Paragraph scale="headerTitle" className="text-left" margin="0px 0px 5px">
                  {data.title || '-'}
                </Paragraph>
                <Paragraph className="text-left" margin="0px 0px 12px">
                  {data.snippet || '-'}
                </Paragraph>
                <a
                  href="/notfound"
                  onClick={() => (window.location.href = data.externalLink || '')}
                  style={{ float: 'right' }}
                  target="_blank"
                >
                  <Text scale="feedbackLink2">Baca Selengkapnya</Text>
                </a>
              </div>
            </Card>
          ))
        ) : (
          <CovidSectionSkeleton />
        )}
      </section>
    </div>
  );
};

const PrixaSectionSkeleton = () => {
  return (
    <Fragment>
      {(() => {
        const cards = [];
        for (let cardLoops = 1; cardLoops <= 5; cardLoops++) {
          cards.push(
            <Card style={{ width: '220px', margin: '0 4px' }} key={cardLoops}>
              <Skeleton height={160} width={192} />
              <br />
              <br />
              <div className="margin-baseB" style={{ width: '100%' }}>
                <Skeleton width={150} />
                <br />
                <Skeleton width={130} />
                <br />
                <Skeleton width={150} />
                <br />
                <Skeleton width={140} />
              </div>
            </Card>,
          );
        }
        return cards;
      })()}
    </Fragment>
  );
};

const CovidSectionSkeleton = () => {
  return (
    <Fragment>
      {(() => {
        const cards = [];
        for (let cardLoops = 1; cardLoops <= 3; cardLoops++) {
          cards.push(
            <Card style={{ display: 'flex', margin: '0px 24px 24px' }} className="padding-base" key={cardLoops}>
              <div
                style={{
                  height: '60',
                  margin: 'auto 24px auto 0px',
                  width: '60',
                  paddingBottom: '10px',
                  paddingLeft: '30px',
                }}
              >
                <Skeleton circle height={60} width={60} />
              </div>
              <div style={{ height: '125px', width: '100%', paddingLeft: '15px', marginTop: '20px' }}>
                <Skeleton width={130} />
                <br />
                <Skeleton width={150} />
                <br />
                <Skeleton width={140} />
                <br />
                <Skeleton width={150} />
                <br />
                <div style={{ marginTop: '20px', paddingLeft: '30px' }}>
                  <Skeleton width={120} />
                </div>
              </div>
            </Card>,
          );
        }
        return cards;
      })()}
    </Fragment>
  );
};

export { IntroPage };
