import React, { useEffect, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Button } from 'prixa-design-kit/dist';
import { postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import { ChangePasswordContext } from './ChangePasswordContext';
import { useInput } from '../../../utils/useInput';

export const ChangePasswordForm = () => {
  const { setError } = useContext(ChangePasswordContext);
  const [isValid, setIsValid] = React.useState(false);
  const [isLoad, setLoad] = React.useState(false);

  const oldPassword = useInput({
    label: 'Password Lama',
    type: 'password',
    placeholder: 'Tulis Password',
  });

  const newPassword = useInput({
    label: 'Password Baru',
    type: 'password',
    placeholder: 'Tulis Password',
  });

  const confirmPassword = useInput({
    label: 'Konfirmasi Password',
    type: 'password',
    placeholder: 'Tulis Password',
    confirmWith: newPassword.value,
  });

  const inputList = [oldPassword.input, newPassword.input, confirmPassword.input];

  const history = useHistory();

  useEffect(() => {
    const isOldPasswordValid = oldPassword.value && !oldPassword.error;
    const isNewPasswordValid = newPassword.value && !newPassword.error;
    const isConfirmPasswordValid = confirmPassword.value && !confirmPassword.error;
    isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid ? setIsValid(true) : setIsValid(false);
  }, [oldPassword, newPassword, confirmPassword]);

  const changePassword = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoad(true);
    postAPI(
      USER_API.CHANGE_PASSWORD,
      {
        password: newPassword.value,
        confirmPassword: confirmPassword.value,
        oldPassword: oldPassword.value,
      },
      undefined,
    )
      .then(() => {
        setLoad(false);
        history.push({
          pathname: '/personal-information',
        });
      })
      .catch(err => {
        setError(
          err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata
            ? err.response.data.details[0].metadata.errInd
            : 'Gangguan sistem, mohon coba kembali.',
        );
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoad(false);
      });
  };

  return (
    <Fragment>
      <div className="prixa-title margin-baseB">
        <Text scale="question">Masukkan password baru untuk akun Anda.</Text>
      </div>
      <div className="margin-baseB">
        <Text scale="caption">
          Pastikan password mengandung huruf kapital, huruf kecil, angka, dan berjumlah minimal 8 karakter
        </Text>
      </div>
      <form onSubmit={(event: React.FormEvent<HTMLFormElement>): void => changePassword(event)}>
        {inputList.map((item, key: number) => {
          return (
            <div key={key} className="margin-baseB">
              {item}
            </div>
          );
        })}
        <div className="text-center margin-largeT">
          <Button
            onClick={(e: any) => changePassword(e)}
            size="xLarge"
            variant="primary"
            disabled={!isValid}
            spinner={isLoad}
          >
            Ganti Password
          </Button>
        </div>
      </form>
    </Fragment>
  );
};
