import axios from 'axios';
import { postAPI, getAPI } from '../api/api-method';
import { DIAGNOSTIC_API } from '../api/api-url';
import { getUID } from '../utils/constant';

const complaint = `${process.env.REACT_APP_ASSET_URL}/illustration/complaint.png`;

export interface GeoLocationType {
  latitude: number;
  longitude: number;
}

export let currentState = '';

export let sessionId =
  localStorage.getItem('sesId') && localStorage.getItem('sesId') !== '' ? localStorage.getItem('sesId') : 'none';
export const geoLocation: GeoLocationType = {
  latitude: 0,
  longitude: 0,
};

export function getConversation(params: { reply?: object; geoLocation?: GeoLocationType }) {
  return new Promise(async (resolve, reject) => {
    try {
      const data: any = await postAPI(DIAGNOSTIC_API.CONVERSATION, {
        sessionId: sessionId,
        reply:
          localStorage.getItem('sesId') && localStorage.getItem('sesId') !== '' && !params.reply
            ? { type: 'resume' }
            : params.reply,
        geoLocation: params.geoLocation || geoLocation,
        uid: getUID,
      });

      let imgSrc;

      // const covidScore = data.result.covidScore;
      const isValidForRDT = data.result.isValidForRDT;
      const diagnoseResult = data.result.actions.diagnosisResult;
      const symptomID = data.result.symptomID;
      currentState = data.result.currentState;
      sessionId = data.sessionId;

      localStorage.setItem('RDTValid', isValidForRDT);

      if (currentState !== 'initial') {
        localStorage.setItem('sesId', data.sessionId);
      }

      const title = data.result.messages.value.split('. ').map((text: string, key: any) => {
        if (key < data.result.messages.value.split('. ').length - 1) {
          return text + '.';
        } else {
          return text;
        }
      });

      const information = data.result.messages.explanation;

      if (currentState === 'askChiefComplaint') {
        imgSrc = complaint;
      }

      const options = () => {
        let buttonText: string | undefined;
        const state = 'initialUserInfo, askPrecondition, diagnosis';

        if (state.includes(currentState)) {
          buttonText = 'Lanjut';
        } else {
          buttonText = undefined;
        }

        if (data.result.actions.type === 'text') {
          return [
            {
              type: 'text',
              text: data.result.actions.value,
              variant: undefined,
              reply: {
                type: 'text',
                value: undefined,
                tag: undefined,
              },
            },
          ];
        } else if (currentState === 'diagnosis') {
          return [
            {
              type: 'button',
              text: buttonText,
              variant: 'primary',
              reply: {
                type: 'button',
                value: String('Lanjutkan'),
              },
            },
          ];
        } else if (data.result.actions.type === 'button') {
          return data.result.actions.value.map((item: any) => {
            return {
              type: data.result.actions.type,
              text: buttonText || item.label,
              variant: data.result.actions.value.length === 1 ? 'primary' : 'secondary',
              description: item.description || '-',
              reply: {
                type: data.result.actions.type,
                value: String(item.value),
                tag: item.tag,
              },
            };
          });
        } else if (data.result.actions.type === 'Preconditions') {
          return data.result.preconditions;
        } else {
          return [];
        }
      };

      let progress: number;

      if (currentState === 'preChiefComplaint') {
        progress = 100;
      } else if (data.progressBarPercentage === 20 && currentState === 'askInsurance') {
        progress = 57;
      } else if (data.progressBarPercentage === 24 && currentState === 'askInsurance') {
        progress = 80;
      } else {
        progress = data.progressBarPercentage;
      }

      const returnData = {
        sessionId: data.sessionId,
        progress: progress || 0,
        state: currentState,
        list: data.result.preconditions || undefined,
        title,
        imgSrc,
        options: options(),
        information,
        diagnoseResult,
        symptomID,
        isValidForRDT,
      };

      resolve(returnData);
    } catch (error) {
      reject(error);
    }
  });
}

export function getNewSession() {
  return new Promise(async (resolve, reject) => {
    try {
      const data: any = await postAPI(DIAGNOSTIC_API.CONVERSATION, {});
      resolve(data.sessionId);
    } catch (error) {
      reject(error);
    }
  });
}

// Cancel token
let token: any;

export const search = (url: string) => {
  return new Promise(async (resolve, reject) => {
    if (token) {
      // Cancel the previous request before making a new request
      token.cancel();
    }

    token = axios.CancelToken.source();

    try {
      const data: any = await getAPI(url, '');
      const result = data.data ? data.data : [];
      resolve(result);
    } catch (error) {
      if (!axios.isCancel(error)) {
        // Handle usual errors
        reject(error);
      }
    }
  });
};
