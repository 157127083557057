import React from 'react';
import { Toast, Sidesheet } from 'prixa-design-kit/dist';
import { ForgetPasswordSideSheet } from '../forget-password-sidesheet/ForgetPasswordSideSheet';
import { LoginContext } from './LoginContext';
import { LoginForm } from './LoginForm';
import { GoogleLogin } from '../../../components/googleLogin/GoogleLogin';

const LoginTab = () => {
  const [isErrorMsg, setError] = React.useState('');
  const [modal, setModal] = React.useState(false);

  return (
    <React.Fragment>
      <LoginContext.Provider value={{ setError, setModal }}>
        <LoginForm />
      </LoginContext.Provider>
      <GoogleLogin text="Masuk dengan Google" />
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      <Sidesheet
        setModal={setModal}
        show={modal}
        className="prixa-sidesheet"
        title="Lupa Password"
        content={<ForgetPasswordSideSheet setModal={setModal} />}
      ></Sidesheet>
    </React.Fragment>
  );
};

export default LoginTab;
