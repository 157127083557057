import React, { useEffect, useState, useRef } from 'react';
import { Text, Toast } from 'prixa-design-kit/dist';
import { SetContainerProps } from '../../App';
import './diagnosisHistoryPage.scss';
import { getAPI } from '../../../api/api-method';
import { HISTORY_API } from '../../../api/api-url';
import { LoadPage } from '../../diagnostic/LoadPage';

interface AssessmentHistory {
  email: string;
  datetime: string;
  chiefComplaint: string;
  name: string;
  age: number;
  gender: string;
}

const DiagnosisHistoryPage: React.FC<SetContainerProps> = (props: SetContainerProps) => {
  const containerHeightRef = useRef<HTMLDivElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<AssessmentHistory[]>([]);
  const [isErrorMsg, setError] = useState('');

  /*eslint-disable */
  useEffect(() => {
    props.setHeader('Riwayat Keluhan');

    return () => props.setHeader('');
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI(HISTORY_API.ASSESMENT_HISTORY)
    .then((res: any) => {
      setError('');
      setLoading(false);
      if (Object.keys(res).length > 0)
        setData(res.assessmentHistory);
    })
    .catch(err => {
      setError(
        err 
          ? err.toString()
          : 'Gangguan sistem, mohon coba kembali.',
      );
      setLoading(false);
    })
  }, []);
  /*eslint-enable */
  if (data && (data.length === 0 || Object.keys(data).length === 0)) {
    if (containerHeightRef && containerHeightRef.current)
      containerHeightRef.current.style.setProperty('--containerHeight--', '0');
  } else if (containerHeightRef && containerHeightRef.current)
    containerHeightRef.current.style.setProperty('--containerHeight--', '48px');

  if (isLoading) return <LoadPage />;

  return (
    <div ref={containerHeightRef} className="prixa-container is-top wrapper">
      <div className="prixa-timeline">
        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            const { datetime, chiefComplaint, name, age, gender } = item;
            const yearDt = datetime.substring(0, 4);
            const monthDt = datetime.substring(5, 7);
            const dateDt = datetime.substring(8, 10);
            return (
              <div key={i}>
                {(i === 0 || yearDt !== data[i - 1].datetime.substring(0, 4)) && (
                  <div className="year-box">
                    <div className="year-line"></div>
                    <Text className="year-text">{yearDt}</Text>
                    <span className="year-line"></span>
                  </div>
                )}
                <div className="list-container">
                  <Text className="date">{`${dateDt}/${monthDt}`}</Text>
                  <div className="circle-box">
                    <div
                      className="upper"
                      style={{
                        position: i === 0 || yearDt !== data[i - 1].datetime.substring(0, 4) ? 'relative' : 'unset',
                      }}
                    ></div>
                    <div className="inner-box">
                      <div className="circle">
                        <div className="inner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="list">
                    <div className="prixa-flex-column">
                      <Text scale="pageTitle" className="title">
                        {chiefComplaint}
                      </Text>
                      <Text scale="content">{`${name}, ${age}, ${gender}`}</Text>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {isErrorMsg === '' && data && (data.length === 0 || Object.keys(data).length === 0) && (
          <div className="year-box">
            <div className="year-line"></div>
            <Text className="year-text">{new Date().getFullYear().toString()}</Text>
            <span className="year-line"></span>
          </div>
        )}
      </div>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </div>
  );
};

export { DiagnosisHistoryPage };
