import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Button, Text, Toast, Icon, Modal, InputText, DropdownItem, Dropdown } from 'prixa-design-kit/dist';
import { useHistory } from 'react-router-dom';
import { useInput } from '../../../utils/useInput';
import { postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './personalInformation.scss';
import { SetContainerProps } from '../../App';

type dtBirth = {
  year: number;
  month: number;
  day: number;
};

const PersonalInformationPage: React.FC<SetContainerProps> = ({
  setHeader,
  editMode,
  setEditMode,
}: SetContainerProps) => {
  const [isLoad, setLoad] = useState(false);
  const [isErrorMsg, setError] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState();
  const [selectedDOB, setSelectedDOB] = useState<dtBirth>();
  const [errNama, setErrNama] = useState(false);
  const [errAlamat, setErrAlamat] = useState(false);
  const [errTelepon, setErrTelepon] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    setHeader('Data Diri');

    return () => {
      setHeader('')
      handleEditMode(false);
    }
  }, []);

  useEffect(() => {
    if (editMode === false) setHeader('Data Diri');
    else setHeader('Edit Data Diri');
  }, [editMode]);

  const name = useInput({
    label: 'Nama',
    type: 'namaDataDiri',
    placeholder: 'Nama Lengkap',
    isValidate: true,
    editMode: editMode
  });

  const address = useInput({
    label: 'Alamat',
    type: 'alamatDataDiri',
    rows: 3,
    placeholder: 'Alamat Lengkap',
    isValidate: true,
    editMode: editMode,
  });

  const phoneNumber = useInput({
    label: 'Nomor Telepon',
    type: 'teleponDataDiri',
    placeholder: 'Nomor Telepon',
    isValidate: true,
    editMode: editMode,
  });

  const inputList = [name.input, address.input, phoneNumber.input];
  const history = useHistory();

  const dayOfBirth = ('0' + selectedDOB?.day).slice(-2);
  const monthOfBirth = ('0' + selectedDOB?.month).slice(-2);
  const yearOfBirth = selectedDOB?.year;
  const DOB = selectedDOB ? dayOfBirth + '/' + monthOfBirth + '/' + yearOfBirth : dateOfBirth ? dateOfBirth : null;
  
  const savePersonalInformation = () => {
    setLoad(true);
    postAPI(
      USER_API.UPDATE,
      {
        profileData: {
          birthdate: DOB,
          phone: phoneNumber.value,
          name: name.value,
          address: address.value,
        },
      },
      undefined
    )
      .then(async (res: any) => {
        setLoad(false);
        handleEditMode(false);
        window.location.reload();
      })
      .catch(err => {
        setError(err.response.data && err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata ? err.response.data.details[0].metadata.errInd : 'Gangguan sistem, mohon coba kembali.');
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoad(false);
        handleEditMode(false);
      });
  };

  useEffect(() => {
    const profileDataLocalStorage = localStorage.getItem('profileData');
    const profileData = JSON.parse(String(profileDataLocalStorage));
    name.setValue && name.setValue(profileData.name);
    setEmail(profileData.email);
    address.setValue && address.setValue(profileData.address);
    phoneNumber.setValue && phoneNumber.setValue(profileData.phone);
    setDateOfBirth(profileData.birthdate);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const isNameValid = name.value && !name.error;
    isNameValid ? setErrNama(false) : setErrNama(true);
  }, [name]);

  useEffect(() => {
    const isAddressValid = address.value && !address.error;
    isAddressValid ? setErrAlamat(false) : setErrAlamat(true);
  }, [address]);

  useEffect(() => {
    const isTeleponValid = phoneNumber.value && !phoneNumber.error;
    isTeleponValid ? setErrTelepon(false) : setErrTelepon(true);
  }, [phoneNumber]);

  const handleEditMode = (mode: boolean): void => {
    if (setEditMode) setEditMode(mode);
  };

  return (
    <React.Fragment>
      <div className="prixa-container is-top">
        <ProfilePictureSection />
        <EmailAndPasswordSection email={email} />
        <DateOfBirthSection
          dateOfBirth={dateOfBirth}
          editMode={editMode}
          selectedDOB={selectedDOB}
          setSelectedDOB={setSelectedDOB}
          name={name}
          address={address}
          phoneNumber={phoneNumber}
        />
        {inputList.map((item, key: number) => {
          return (
            <div key={key} className="margin-baseB">
              {item}
            </div>
          );
        })}
      </div>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      {editMode && (
        <SaveButton
          errAlamat={errAlamat}
          errNama={errNama}
          errTelepon={errTelepon}
          onSave={savePersonalInformation}
          isLoad={isLoad}
        />
      )}
    </React.Fragment>
  );
};

const ProfilePictureSection = (): JSX.Element => {
  const [load, setLoad] = useState(false);
  // const [url, setURL] = useState('https://picsum.photos/200');
  // const [isErrorMsg, setError] = React.useState('');
  const isErrorMsg = '';
  const url = 'https://picsum.photos/200';

  // const uploadImage = async (e: any) => {
  //   try {
  //     setLoad(true);
  //     const file = e.target.files[0];
  //     const upload: any = await postAPI(
  //       USER_API.UPLOAD_URL,
  //       {},
  //       {
  //         Authorization: `Bearer ${String(localStorage.getItem('loginToken'))}`,
  //         'Content-Type': file.type,
  //         'X-Prixa-API-Key': `${process.env.REACT_APP_API_KEY}`,
  //         'X-Partner-Id': getPartnerID,
  //         'X-Partner-App-Id': getAppID,
  //       },
  //     );

  //     await putAPI(upload.url, file, {
  //       'Content-Type': file.type,
  //     });

  //     postAPI(
  //       USER_API.UPDATE,
  //       {
  //         profileData: {
  //           avatarURL: upload.name,
  //         },
  //       },
  //       undefined,
  //       String(localStorage.getItem('loginToken')),
  //     ).then(async (res: any) => {
  //       window.location.reload();
  //     });
  //   } catch (error) {
  //     setLoad(false);
  //     setError("Can't upload image");
  //     setTimeout(() => {
  //       setError('');
  //     }, 3000);
  //   }
  // };

  useEffect(() => {
    setLoad(true);
    // const profileDataLocalStorage = localStorage.getItem('profileData');
    // const profileData = JSON.parse(String(profileDataLocalStorage));
    // profileData && profileData.avatarURL && setURL(profileData.avatarURL || 'https://picsum.photos/200');
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  return (
    <div className="text-center margin-baseB">
      {load ? (
        <div style={{ width: '20px', margin: 'auto' }}>
          <Icon color="primary" type="faCircleNotch" spin />
        </div>
      ) : (
        <img
          loading="lazy"
          height="64px"
          width="64px"
          alt="User Profile"
          src={url}
          style={{
            borderRadius: '100%',
            height: '64px',
            width: '64px',
            objectFit: 'cover',
            border: '4px solid #aaaaaa',
            marginBottom: '8px',
          }}
        />
      )}
      <br />
      {/* <input
        type="file"
        id="prixa-file-upload"
        onChange={e => {
          uploadImage(e);
        }}
      /> */}
      {/* <Text scale="feedbackLink2">Ganti Foto</Text> */}
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
    </div>
  );
};

const EmailAndPasswordSection = ({ email }: any): JSX.Element => {
  return (
    <div className="prixa-pop-container margin-baseB">
      <EmailSection email={email} />
      <PasswordSection />
    </div>
  );
};

const EmailSection = ({ email }: any): JSX.Element => {
  return (
    <div className="prixa-space-between padding-tinyB" style={{ borderBottom: '1px solid rgb(112,112,112, .25)' }}>
      <Text scale="content" style={{ fontWeight: 'bold' }}>
        Email
      </Text>
      <Text scale="content">{email}</Text>
    </div>
  );
};

const PasswordSection = (): JSX.Element => {
  return (
    <a href="/change-password" className="prixa-space-between padding-tinyT">
      <Text scale="content" style={{ fontWeight: 'bold' }}>
        Ganti Password
      </Text>
      <Icon style={{ color: '#4C4F54' }} type="faChevronRight" />
    </a>
  );
};

const monthOptions = [
  { value: 1, text: 'Januari' },
  { value: 2, text: 'Februari' },
  { value: 3, text: 'Maret' },
  { value: 4, text: 'April' },
  { value: 5, text: 'Mei' },
  { value: 6, text: 'Juni' },
  { value: 7, text: 'Juli' },
  { value: 8, text: 'Agustus' },
  { value: 9, text: 'September' },
  { value: 10, text: 'Oktober' },
  { value: 11, text: 'November' },
  { value: 12, text: 'Desember' },
];

const DateOfBirthSection = ({
  dateOfBirth,
  editMode,
  selectedDOB,
  setSelectedDOB,
  name,
  address,
  phoneNumber,
}: any): JSX.Element => {
  const [showModal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [dOfBirth, setDOfBirth] = useState<string>('');
  const [mOfBirth, setMOfBirth] = useState<string>('');
  const [yOfBirth, setYOfBirth] = useState<string>('');
  const [nameMOfBirth, setNameMOfBirth] = useState<string | undefined>('');
  const [showDOBText, setShowDOBText] = useState(false);
  const [isErrorMsg, setError] = useState('');
  const [isLoadButton, setIsLoadButton] = useState(false);

  useEffect(() => {
    if (!selectedDOB) {
      setShowDOBText(false);
      setDOfBirth('');
      setMOfBirth('');
      setYOfBirth('');
      setNameMOfBirth('');
    }
  }, [selectedDOB]);

  useEffect(() => {
    if (!dOfBirth || !mOfBirth || !yOfBirth) setDisabled(true);
    else if (parseInt(dOfBirth) < 1 || parseInt(dOfBirth) > 31) setDisabled(true);
    else if (yOfBirth.length < 4) setDisabled(true);
    else if (`${yOfBirth}${('0' + mOfBirth).slice(-2)}${('0' + dOfBirth).slice(-2)}` > moment().format('YYYYMMDD'))
      setDisabled(true);
    else setDisabled(false);
  }, [dOfBirth, mOfBirth, yOfBirth]);

  const handleDayChange = (value: string): void => {
    const regexp = /^[0-9\b]+$/;
    if (value === '' || regexp.test(value)) {
      setDOfBirth(value);
    }
  };

  const handleYearChange = (value: string): void => {
    const regexp = /^[0-9\b]+$/;
    if (value === '' || regexp.test(value)) {
      setYOfBirth(value);
    }
  };

  const handleMonthSelect = useCallback((month: DropdownItem) => {
    setMOfBirth(month.value);
    setNameMOfBirth(month.text);
  }, []);

  const saveBirthDate = (): void => {
    setIsLoadButton(true);

    const DOB = `${('0' + dOfBirth).slice(-2)}/${('0' + mOfBirth).slice(-2)}/${yOfBirth}`;

    postAPI(
      USER_API.UPDATE,
      {
        profileData: {
          birthdate: DOB,
          phone: phoneNumber.value,
          name: name.value,
          address: address.value,
        },
      },
      undefined,
    )
      .then(async () => {
        setIsLoadButton(false);
        setModal(false);
      })
      .catch(err => {
        setError(
          err.response &&
            err.response.data.details &&
            err.response.data.details[0] &&
            err.response.data.details[0].metadata
            ? err.response.data.details[0].metadata.errInd
            : 'Gangguan sistem, mohon coba kembali.',
        );
        setTimeout(() => {
          setIsLoadButton(false);
          setError('');
        }, 3000);
      });
  };

  return (
    <React.Fragment>
      <div className="prixa-pop-container margin-largeB">
        <div className="prixa-space-between">
          <Text scale="content" style={{ fontWeight: 'bold' }}>
            Tanggal Lahir
          </Text>
          {dateOfBirth ? (
            <Text scale="content" style={{ display: 'flex', alignItems: 'center' }}>
              {dateOfBirth}
            </Text>
          ) : selectedDOB ? (
            <Text scale="content" style={{ display: 'flex', alignItems: 'center' }}>
              {('0' + selectedDOB.day).slice(-2)}/{('0' + selectedDOB.month).slice(-2)}/{selectedDOB.year}
            </Text>
          ) : (
            <div onClick={editMode ? (): void => setModal(true) : (): object => ({})}>
              <Text
                scale="feedbackLink"
                style={{ cursor: editMode ? 'pointer' : 'default', color: editMode ? '' : '#aaaaaa' }}
              >
                Tambah
              </Text>
            </div>
          )}
        </div>
      </div>
      <Toast timeout={3000} message={isErrorMsg} variant="danger" show={isErrorMsg !== ''}></Toast>
      <Modal
        show={showModal}
        content={
          <React.Fragment>
            <Text className="margin-baseB" scale="question" style={{ color: '#4c4f54' }}>
              Tanggal Lahir
            </Text>
            <div style={{ display: 'flex', marginBottom: 24 }}>
              {showDOBText && (
                <div style={{ margin: '0 auto' }}>
                  <Text scale="heroTitle" style={{ marginRight: 24 }}>
                    {dOfBirth}
                  </Text>
                  <Text scale="heroTitle" style={{ marginRight: 24 }}>
                    {nameMOfBirth}
                  </Text>
                  <Text scale="heroTitle">{yOfBirth}</Text>
                </div>
              )}
              {!showDOBText && (
                <>
                  <InputText
                    setData={(value: string): void => handleDayChange(value)}
                    value={dOfBirth}
                    maxLength={2}
                    type="text"
                    placeholder="Tanggal"
                    style={{ flex: 1, flexBasis: '27.5%', height: 48, width: 81 }}
                  />
                  <Dropdown
                    options={monthOptions}
                    placeholder="Bulan"
                    onSelect={handleMonthSelect}
                    value={nameMOfBirth}
                    styleContainer={{ width: 117, margin: '0 8px 0' }}
                    styleInput={{ fontSize: 13 }}
                    styleItems={{ fontSize: 12, maxHeight: 135 }}
                  />
                  <InputText
                    setData={(value: string): void => handleYearChange(value)}
                    value={yOfBirth}
                    maxLength={4}
                    type="text"
                    placeholder="Tahun"
                    style={{ flex: 1, flexBasis: '27.5%', height: 48, width: 81 }}
                  />
                </>
              )}
            </div>
            <Text className="margin-baseB" scale="caption" style={{ color: '#4c4f54', fontSize: 14, marginBottom: 28 }}>
              Tanggal lahir yang Anda tambahkan tidak dapat diganti setelah disimpan. Pastikan tanggal lahir Anda sudah
              sesuai.
            </Text>
          </React.Fragment>
        }
        buttons={
          <React.Fragment>
            {showDOBText && (
              <React.Fragment>
                <Button
                  onClick={(): void => {
                    setShowDOBText(false);
                  }}
                  size="full"
                  variant="disabled"
                  className="prixa-cancel-button"
                >
                  Ubah
                </Button>
                <Button
                  onClick={(): void => {
                    setSelectedDOB({ year: parseInt(yOfBirth), month: parseInt(mOfBirth), day: parseInt(dOfBirth) });
                    saveBirthDate();
                  }}
                  size="full"
                  variant="primary"
                  spinner={isLoadButton}
                >
                  Simpan
                </Button>
              </React.Fragment>
            )}
            {!showDOBText && (
              <React.Fragment>
                <Button
                  onClick={(): void => {
                    if (!selectedDOB) setSelectedDOB(undefined);
                    setModal(false);
                  }}
                  size="full"
                  variant="disabled"
                  className="prixa-cancel-button"
                >
                  Batal
                </Button>
                <Button
                  onClick={(): void => {
                    setShowDOBText(true);
                  }}
                  size="full"
                  variant="primary"
                  disabled={isDisabled}
                >
                  Simpan
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

const SaveButton = ({ errAlamat, errNama, errTelepon, onSave, isLoad }: any): JSX.Element => {
  return (
    <div className="prixa-footer-button">
      <Button
        onClick={(): void => onSave()}
        size="full"
        variant="primary"
        disabled={errAlamat || errNama || errTelepon}
        spinner={isLoad}
      >
        Simpan
      </Button>
    </div>
  );
};

export { PersonalInformationPage };
