import React from 'react';
import { HomePage } from '../wrappers/user/HomePage';
import { InitiateChatPage } from '../wrappers/user/initiate-chat-page/InitiateChatPage';
import { VerificationCheckPage } from '../wrappers/user/VerificationCheckPage';
import { InitialPage } from '../wrappers/user/InitialPage';
import { ContinuePage } from '../wrappers/user/continue-page/ContinuePage';
import { ResetPasswordPage, ResetPasswordSuccessPage } from '../wrappers/user/reset-password-page/ResetPasswordPage';
import { VerificationSentPage } from '../wrappers/user/VerificationPage';
import { IntroPage } from '../wrappers/diagnostic/IntroPage';
import { IntroPage as IntroPageCovid } from '../wrappers/covid/intro-page/IntroPage';
import { Consent, ConsentAccept, ConsentRejectConfirmation, ConsentReject } from '../wrappers/diagnostic/ConsentPage';
import { ConversationPage } from '../wrappers/diagnostic/ConversationPage';
import { SetContainerProps } from '../wrappers/App';
import { ProfilePage } from '../wrappers/user/ProfilePage';
import { PersonalInformationPage } from '../wrappers/user/personal-information-page/PersonalInformationPage';
import { DiagnosisHistoryPage } from '../wrappers/user/diagnosis-history-page/DiagnosisHistoryPage';
import { ChangePasswordPage } from '../wrappers/user/change-password-page/ChangePasswordPage';
import { LandingPage } from '../wrappers/booking/landing-page';
import { BookingDatePage } from '../wrappers/booking/booking-page/BookingDatePage';
import { BookingTimePage } from '../wrappers/booking/booking-page/BookingTimePage';
import { BookingDataPage } from '../wrappers/booking/booking-page/BookingDataPage';
import { BookingConfirmationPage } from '../wrappers/booking/booking-page/BookingConfirmationPage';
import { BookingSummaryPage } from '../wrappers/booking/booking-page/BookingSummaryPage';
import { BookingContext } from '../wrappers/booking/booking-page/BookingContext';
import { AllSpecialityPage } from '../wrappers/booking/landing-page/AllSpecialityPage';
import { SpecialityType } from '../wrappers/booking/landing-page/SpecialitiesSection';
import { AllHospitalPage } from '../wrappers/booking/landing-page/AllHospitalPage';
import { SearchDoctorPage } from '../wrappers/booking/SearchDoctorPage';
import { FilterDataType, FilterPage } from '../wrappers/booking/FilterPage';
import HealthInformation from '../wrappers/user/health-info-page/HealthInformation';
import { HospitalPage } from '../wrappers/booking/landing-page/HospitalPage';
import { showUserManagement, showTelemedFeature, showBookingeature } from '../utils/constant';
import { sessionId } from '../api/api-utils';

interface SetBookingProps {
  doctorData?: any;
  setDoctorData?: any;
  date?: any;
  setDate?: any;
  time?: any;
  setTime?: any;
  bookingData?: any;
  setBookingData?: any;
  allSpecialities?: Array<SpecialityType> | undefined;
  setAllSpecialities?: (data: any) => void;
  setHeader?: any;
  setMenu?: any;
  filterData?: FilterDataType;
  setFilterData?: (data: any) => void;
}

export const bookingURLs = ({
  doctorData,
  setDoctorData,
  date,
  setDate,
  time,
  setTime,
  bookingData,
  setBookingData,
  allSpecialities,
  setAllSpecialities,
  setHeader,
  setMenu,
  filterData,
  setFilterData,
}: SetBookingProps) => [
  {
    path: '/booking',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ setDoctorData, setDate, setTime, setBookingData, filterData, setFilterData }}>
        <LandingPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/all-speciality',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ allSpecialities, setAllSpecialities, filterData, setFilterData }}>
        <AllSpecialityPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/all-hospital',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ filterData, setFilterData }}>
        <AllHospitalPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/hospital/:hospitalName',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ setFilterData, setAllSpecialities }}>
        <HospitalPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/filter',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ filterData, setFilterData, allSpecialities, setAllSpecialities }}>
        <FilterPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/search-doctor',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ filterData, setDoctorData, allSpecialities }}>
        <SearchDoctorPage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/booking-date',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ doctorData, setDoctorData, date, setDate, time, setTime }}>
        <BookingDatePage setHeader={setHeader} setMenu={setMenu} />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/booking-time',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ doctorData, date, time, setTime }}>
        <BookingTimePage />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/booking-data',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ doctorData, date, time, setBookingData, bookingData }}>
        <BookingDataPage />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/booking-confirmation',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: (
      <BookingContext.Provider value={{ doctorData, date, time, bookingData }}>
        <BookingConfirmationPage />
      </BookingContext.Provider>
    ),
  },
  {
    path: '/booking-summary',
    exact: true,
    enable: showBookingeature() ? true : false,
    component: <BookingSummaryPage />,
  },
];

export const loggedURLs = (props: SetContainerProps) => [
  {
    path: '/home',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: <HomePage />,
  },
  {
    path:
      localStorage.getItem('telemedicineSDKURL') === ''
        ? '/initiate'
        : `${localStorage.getItem('telemedicineSDKURL')}?sessionID=${sessionId}`,
    exact: true,
    enable: showTelemedFeature() ? true : false,
    component: <InitiateChatPage />,
  },
  {
    path: '/unverified',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: <VerificationCheckPage />,
  },
  {
    path: '/profile',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: <ProfilePage />,
  },
  {
    path: '/personal-information',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: (
      <PersonalInformationPage
        setHeader={props.setHeader}
        setPercentage={props.setPercentage}
        setError={props.setError}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
      />
    ),
  },
  {
    path: '/change-password',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: <ChangePasswordPage />,
  },
  {
    path: '/diagnosis-history',
    exact: true,
    enable: showUserManagement() ? true : false,
    component: (
      <DiagnosisHistoryPage setHeader={props.setHeader} setPercentage={props.setPercentage} setError={props.setError} />
    ),
  },
  {
    path: '/precondition-info',
    exact: true,
    enable: true,
    component: (
      <HealthInformation
        setHeader={props.setHeader}
        setPercentage={props.setPercentage}
        setError={props.setError}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
      />
    ),
  },
];

export const regisSign = [
  {
    path: '/user',
    exact: true,
    enable: true,
    component: <InitialPage />,
  },
  {
    path: '/continue',
    exact: true,
    enable: true,
    component: <ContinuePage />,
  },
  {
    path: '/verification-sent',
    exact: true,
    enable: true,
    component: <VerificationSentPage />,
  },
  {
    path: '/reset-password/:tokenPassword',
    exact: true,
    enable: true,
    component: <ResetPasswordPage />,
  },
  {
    path: '/reset-password-sent',
    exact: true,
    enable: true,
    component: <ResetPasswordSuccessPage />,
  },
  {
    path: '/verification-check/:tokenVerify',
    exact: true,
    enable: true,
    component: <VerificationCheckPage />,
  },
];

export const diagnosisEngine = (props: SetContainerProps) => [
  {
    path: '/',
    exact: true,
    enable: true,
    component: process.env.REACT_APP_ACTIVE === 'covid' ? <IntroPageCovid /> : <IntroPage />,
  },
  {
    path: '/partner/:partnerId/app/:applicationId',
    exact: true,
    enable: true,
    component: process.env.REACT_APP_ACTIVE === 'covid' ? <IntroPageCovid /> : <IntroPage />,
  },
  {
    path: '/consent',
    exact: true,
    enable: true,
    component: <Consent />,
  },
  {
    path: '/consent-reject-confirmation',
    exact: true,
    enable: true,
    component: <ConsentRejectConfirmation />,
  },
  {
    path: '/consent-reject',
    exact: true,
    enable: true,
    component: <ConsentReject />,
  },
  {
    path: '/conversation',
    exact: true,
    enable: true,
    component: (
      <ConversationPage setHeader={props.setHeader} setPercentage={props.setPercentage} setError={props.setError} />
    ),
  },
  {
    path: '/consent-accept',
    exact: true,
    enable: true,
    component: <ConsentAccept />,
  },
];
