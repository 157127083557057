import moment from 'moment';
import { PrecondListInt } from './HealthInformation';
import { getAPI, postAPI } from '../../../api/api-method';
import { USER_API } from '../../../api/api-url';

export const calcAge = (dateFrom: Date, dateTo: Date = new Date()): { age: number; type: string } => {
  const endDate = moment(dateTo);
  const startDate = moment(dateFrom);

  const age = endDate.diff(startDate, 'years');

  if (age > 0) return { age, type: 'year' };
  else return { age: endDate.diff(startDate, 'months'), type: 'month' };
};

export const getPreconditionsList = (
  setAgeYear: React.Dispatch<React.SetStateAction<number>>,
  setAgeMonth: React.Dispatch<React.SetStateAction<number>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPreconditionData: React.Dispatch<React.SetStateAction<PrecondListInt[]>>,
  descriptivePrecondition: PrecondListInt[],
  setGenderList: React.Dispatch<React.SetStateAction<PrecondListInt[]>>,
  setDescriptivePrecondition: React.Dispatch<React.SetStateAction<PrecondListInt[]>>,
  setGender: React.Dispatch<React.SetStateAction<PrecondListInt | undefined>>,
  setWeight: React.Dispatch<React.SetStateAction<number>>,
  setHeight: React.Dispatch<React.SetStateAction<number>>,
  setDarahTinggi: React.Dispatch<React.SetStateAction<boolean>>,
  setDiabetes: React.Dispatch<React.SetStateAction<boolean>>,
  setMerokok: React.Dispatch<React.SetStateAction<boolean>>,
  setMinumAlkohol: React.Dispatch<React.SetStateAction<boolean>>,
  setKanker: React.Dispatch<React.SetStateAction<boolean>>,
  setSakitJantung: React.Dispatch<React.SetStateAction<boolean>>,
  setGagalGinjal: React.Dispatch<React.SetStateAction<boolean>>,
  setHamil: React.Dispatch<React.SetStateAction<boolean>>,
  setMenopause: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  /*eslint-disable*/
  getAPI(USER_API.PRECONDITIONS)
    .then((res: any) => {
      setAgeYear(0);
      setAgeMonth(0);
      setError('');
      setLoading(false);
      
      if (res && res['AllPreconditions']) {
        setPreconditionData(res['AllPreconditions']);

        const gList: PrecondListInt[] = [];
        const descPrecondition: PrecondListInt[] = [];

        res['AllPreconditions'].map((precond: PrecondListInt) => {
          if (precond.name === 'Female' || precond.name === 'Male') {
            gList.push(precond);
          } else if (precond.preconditionsDescription) {
            descriptivePrecondition.push(precond);
          }
        });

        if (gList.length > 0) setGenderList(gList);

        if (descPrecondition.length > 0) setDescriptivePrecondition(descPrecondition);

        const profileData = localStorage.getItem('profileData');
        let birthDate;

        if (profileData) birthDate = JSON.parse(profileData).birthdate;

        if (birthDate) {
          const yr = birthDate.substr(6, 4);
          const mt = birthDate.substr(3, 2);
          const dt = birthDate.substr(0, 2);
          const brthDt = new Date(`${yr}/${mt}/${dt}`);

          const cAge = calcAge(brthDt);

          if (cAge.type === 'year')
            setAgeYear(cAge.age);
          else 
            setAgeMonth(cAge.age);
        }

        if (res.precondList) {
          setUserPrecond(
            res.precondList, 
            setGender, 
            setWeight, 
            setHeight, 
            setDarahTinggi, 
            setDiabetes, 
            setMerokok, 
            setMinumAlkohol, 
            setKanker, 
            setSakitJantung, 
            setGagalGinjal, 
            setHamil, 
            setMenopause
          );
        }
      }
    })
    .catch(err => {
      setAgeYear(0);
      setAgeMonth(0);
      setError(
        err.response && err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata
          ? err.response.data.details[0].metadata.errInd
          : 'Gangguan sistem, mohon coba kembali.',
      );
      setLoading(false);
    });
    /*esling-enable*/
};

export const setUserPrecond = (
  userPrecond: PrecondListInt[],
  setGender: React.Dispatch<React.SetStateAction<PrecondListInt | undefined>>,
  setWeight: React.Dispatch<React.SetStateAction<number>>,
  setHeight: React.Dispatch<React.SetStateAction<number>>,
  setDarahTinggi: React.Dispatch<React.SetStateAction<boolean>>,
  setDiabetes: React.Dispatch<React.SetStateAction<boolean>>,
  setMerokok: React.Dispatch<React.SetStateAction<boolean>>,
  setMinumAlkohol: React.Dispatch<React.SetStateAction<boolean>>,
  setKanker: React.Dispatch<React.SetStateAction<boolean>>,
  setSakitJantung: React.Dispatch<React.SetStateAction<boolean>>,
  setGagalGinjal: React.Dispatch<React.SetStateAction<boolean>>,
  setHamil: React.Dispatch<React.SetStateAction<boolean>>,
  setMenopause: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  setGender(
    userPrecond.find(el => {
      return el.type === 'gender';
    }),
  );

  // let weightHeight: PrecondListInt | undefined;
  const weightHeight = userPrecond.find(el => {
    return el.type === 'bmi' && el.weight && el.height;
  });
  setWeight(weightHeight?.weight ? weightHeight.weight : 0);
  setHeight(weightHeight?.height ? weightHeight.height : 0);

  if (
    userPrecond.find(el => {
      return el.name === 'Hypertensive';
    })
  )
    setDarahTinggi(true);
  else setDarahTinggi(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Diabetic';
    })
  )
    setDiabetes(true);
  else setDiabetes(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Smoker';
    })
  )
    setMerokok(true);
  else setMerokok(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Alcohol drinker';
    })
  )
    setMinumAlkohol(true);
  else setMinumAlkohol(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Cancer';
    })
  )
    setKanker(true);
  else setKanker(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Cardiovascular Disease';
    })
  )
    setSakitJantung(true);
  else setSakitJantung(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Chronic Kidney Failure';
    })
  )
    setGagalGinjal(true);
  else setGagalGinjal(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Pregnant';
    })
  )
    setHamil(true);
  else setHamil(false);

  if (
    userPrecond.find(el => {
      return el.name === 'Menopause';
    })
  )
    setMenopause(true);
  else setMenopause(false);
};

export const handleSubmit = async (
  setIsLoadButton: React.Dispatch<React.SetStateAction<boolean>>,
  gender: PrecondListInt | undefined,
  height: number,
  weight: number,
  ageMonth: number,
  ageYear: number,
  preconditionData: PrecondListInt[],
  darahTinggi: boolean,
  diabetes: boolean,
  merokok: boolean,
  minumAlkohol: boolean,
  kanker: boolean,
  sakitJantung: boolean,
  gagalGinjal: boolean,
  hamil: boolean,
  menopause: boolean,
  setError: React.Dispatch<React.SetStateAction<string>>,
  handleEditMode: (mode: boolean) => void,
): Promise<void> => {
  setIsLoadButton(true);

  const precondList = [];

  precondList.push(gender);

  precondList.push({
    height: Number(height),
    weight: Number(weight),
    type: 'bmi',
  });

  precondList.push({
    ageYear: Number(ageYear),
    ageMonth: Number(ageMonth),
    type: 'age',
  });

  if (darahTinggi) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Hypertensive';
      }),
    );
  }

  if (diabetes) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Diabetic';
      }),
    );
  }

  if (merokok) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Smoker';
      }),
    );
  }

  if (minumAlkohol) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Alcohol drinker';
      }),
    );
  }

  if (kanker) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Cancer';
      }),
    );
  }

  if (sakitJantung) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Cardiovascular Disease';
      }),
    );
  }

  if (gagalGinjal) {
    precondList.push(
      preconditionData.find(el => {
        return el.name === 'Chronic Kidney Failure';
      }),
    );
  }

  if (gender?.name === 'Female') {
    if (hamil) {
      precondList.push(
        preconditionData.find(el => {
          return el.name === 'Pregnant';
        }),
      );
    } else {
      precondList.push(
        preconditionData.find(el => {
          return el.name === 'Not Pregnant';
        }),
      );
    }

    if (menopause) {
      precondList.push(
        preconditionData.find(el => {
          return el.name === 'Menopause';
        }),
      );
    } else {
      precondList.push(
        preconditionData.find(el => {
          return el.name === 'Not Menopause';
        }),
      );
    }
  }

  const precondPayload = {
    precondList: precondList,
  };

  try {
    // eslint-disable-next-line
    const data = await postAPI(USER_API.PRECONDITIONS, precondPayload);
    setIsLoadButton(false);
    handleEditMode(false);
    window.location.reload();
  } catch (err) {
    setError(
      err.response && err.response.data.details && err.response.data.details[0] && err.response.data.details[0].metadata
        ? err.response.data.details[0].metadata.errInd
        : 'Gangguan sistem, mohon coba kembali.',
    );
    setIsLoadButton(false);
    handleEditMode(false);
  }
};
