import React, { useEffect } from 'react';
import '../conversation/conversation.scss';
import { Link } from 'react-router-dom';
import { Button, Text, Paragraph, InputText, Toast, Icon, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import { CSSTransition } from 'react-transition-group';
import InfoSideSheet from '../../wrappers/diagnostic/InfoSideSheet';
import QuestionFeedbackSideSheet, { QuestionFeedback } from '../../wrappers/diagnostic/QuestionFeedbackSideSheet';
import { getAPI } from '../../api/api-method';
import { DIAGNOSTIC_API } from '../../api/api-url';

export interface ConversationProps {
  information?: string | null;
  title: Array<string>;
  subtitle?: string;
  imgSrc?: string;
  loading?: boolean;
  options: Option[];
  callFunction?: any;
  search?: string;
  setSearchQuery?: any;
  state?: string;
  sessionId?: string;
  symptomID?: string | null;
}

export interface Option {
  type: string;
  text: string;
  link?: string;
  reply?: {
    value?: any;
    tag?: string;
  };
  variant: 'default' | 'outline' | 'primary' | 'disabled' | 'secondary' | 'success' | undefined;
}

const Conversation = (props: ConversationProps) => {
  const [inProp, setInProp] = React.useState(true);
  const [information, setInformation] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);
  const [errSearch, setErrSearchQuery] = React.useState(false);
  const [toast, setToast] = React.useState('');
  const [toastVariant, setToastVariant] = React.useState('success');
  const [feedbackQuestion, setFeedbackQuestion] = React.useState<QuestionFeedback | undefined>(undefined);

  const submitData = (event: any) => {
    event.preventDefault();
    const data = {
      type: 'text',
      value: props.search,
      tag: props.search,
    };

    if (data.value && props.callFunction) {
      setInProp(false);
      props.callFunction(data).then((res: string) => {
        if (res !== 'showComplaint') {
          setInProp(true);
        }
      });
    }
  };

  useEffect(() => {
    setErrSearchQuery(!props.search || props.search === '');
  }, [props.search]);

  const viewFeedback = () => {
    getAPI(DIAGNOSTIC_API.FEEDBACK).then(async (res: any) => {
      const feedbackProps = {
        sessionId: props.sessionId,
        title: props.title,
        symptomID: props.symptomID,
        setModal: setFeedback,
        setToast: setToast,
        setToastVariant: setToastVariant,
      };
      const feedbackData = { ...res, ...feedbackProps };
      setFeedbackQuestion(feedbackData);
      setFeedback(true);
      openSideSheet();
    });
  };

  return (
    <div className="prixa-container">
      <Toast timeout={3000} message={toast} variant={toastVariant} show={toast !== ''}></Toast>
      <CSSTransition
        in={inProp}
        unmountOnExit
        timeout={500}
        classNames={{
          enter: 'animated',
          enterActive: 'fadeInUp super-fast',
          exit: 'animated',
          exitActive: 'fadeOutUp super-fast',
        }}
      >
        <div className="prixa-body">
          {(() => {
            if (props.imgSrc) {
              return (
                <div className="prixa-question-image">
                  <img loading="lazy" width="240px" alt="Prixa Conversation" src={props.imgSrc} />
                </div>
              );
            }
          })()}
          <div className="prixa-title">
            {(() => {
              if (props.information) {
                return (
                  <div
                    onClick={() => {
                      setInformation(true);
                    }}
                  >
                    <Icon type="faInfo" className="prixa-info-icon margin-baseB" />
                  </div>
                );
              }
            })()}
            {props.title.map((title, i) => {
              return (
                <Paragraph key={i} scale="question" data-cy="text-title-question">
                  {title}
                </Paragraph>
              );
            })}
            {(() => {
              if (props.subtitle) {
                return (
                  <Paragraph className="prixa-title-sub" scale="caption" data-cy="text-subtitle-question">
                    {props.subtitle}
                  </Paragraph>
                );
              }
            })()}
            {(() => {
              if (props.state === 'askSymptom') {
                return (
                  <span onClick={() => viewFeedback()}>
                    <Text
                      scale="feedbackLink"
                      data-cy="link-feedback"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      BERI FEEDBACK
                      <Icon type="faArrowCircleRight" className="margin-tinyL" color="secondary" />
                    </Text>
                  </span>
                );
              }
            })()}
          </div>
        </div>
      </CSSTransition>
      <div>
        {props.options[0] && props.options[0].link ? (
          props.options.map((option: Option, i) => {
            return (
              <div
                className="prixa-right-button"
                key={i}
                data-cy={`button-option`}
                // onClick={() => {
                //   setInProp(false);
                // }}
              >
                <CSSTransition
                  in={inProp}
                  unmountOnExit
                  timeout={500}
                  classNames={{
                    enter: 'animated',
                    enterActive: 'fadeInRight super-fast',
                    exit: 'animated',
                    exitActive: 'fadeOutRight super-fast',
                  }}
                >
                  <Link to={option.link || '/'}>
                    <Button
                      size="option"
                      className="dav-special"
                      variant={option.variant}
                      style={{ marginTop: '20px' }}
                    >
                      {option.text}
                    </Button>
                  </Link>
                </CSSTransition>
              </div>
            );
          })
        ) : (
          <span />
        )}

        {props.options[0] && props.options[0].type === 'text' ? (
          <CSSTransition
            in={inProp}
            unmountOnExit
            timeout={500}
            classNames={{
              enter: 'animated',
              enterActive: 'fadeInRight super-fast',
              exit: 'animated',
              exitActive: 'fadeOutRight super-fast',
            }}
          >
            <form
              onSubmit={event => {
                submitData(event);
              }}
            >
              <InputText
                icon
                type="text"
                placeholder={JSON.parse(JSON.stringify(props.options[0].text[0])).value}
                setData={props.setSearchQuery}
                onClick={submitData}
                disabledButton={errSearch}
                data-cy={`input-text-${JSON.parse(JSON.stringify(props.options[0].text[0])).value}`}
              />
            </form>
          </CSSTransition>
        ) : (
          <span />
        )}

        {props.options[0] && !props.options[0].link && props.options[0].type === 'button' ? (
          props.options.map((option: Option, i) => {
            return (
              <div
                className="prixa-right-button"
                data-cy={`button-option`}
                key={i}
                onClick={() => {
                  if (props.callFunction) {
                    setInProp(false);
                    props.callFunction(option.reply).then((res: string) => {
                      if (res !== 'askPrecondition') {
                        setInProp(true);
                      }
                    });
                  } else {
                    return '';
                  }
                }}
              >
                <CSSTransition
                  in={inProp}
                  unmountOnExit
                  timeout={500}
                  classNames={{
                    enter: 'animated',
                    enterActive: 'fadeInRight super-fast',
                    exit: 'animated',
                    exitActive: 'fadeOutRight super-fast',
                  }}
                >
                  <Button size="option" className="dav-special" variant={option.variant}>
                    {option.text}
                  </Button>
                </CSSTransition>
              </div>
            );
          })
        ) : (
          <span />
        )}
      </div>
      <Sidesheet
        setModal={setInformation}
        show={information}
        title="Penjelasan"
        className="prixa-sidesheet"
        content={<InfoSideSheet content={props.information} />}
      ></Sidesheet>
      {feedbackQuestion && (
        <Sidesheet
          setModal={setFeedback}
          show={feedback}
          title="Beri Feedback"
          className="prixa-sidesheet"
          content={<QuestionFeedbackSideSheet {...feedbackQuestion} />}
        ></Sidesheet>
      )}
    </div>
  );
};

export default Conversation;
