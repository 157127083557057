import React from 'react';
import './header.scss';
import { Text, Icon, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import MenuPage from '../menu/Menu';
import { Link } from 'react-router-dom';
import { sessionId, currentState } from '../../api/api-utils';
import { UseTracking } from '../../utils/useTracking';
import { partnerTheme } from '../../utils/constant';

interface HeaderProps {
  title?: string;
  subtitle?: string;
  showMenu?: boolean;
  showFilter?: boolean;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  showEdit?: boolean;
}

const Header = (props: HeaderProps) => {
  const [modal, setModal] = React.useState(false);
  const [{ title, subtitle }, setHeader] = React.useState({ title: '', subtitle: '' });
  const theme = partnerTheme.get();
  const imgHeader = (theme && theme.partnerLogo) || `${process.env.REACT_APP_ASSET_URL}/prixa-header.png`;

  const pageWithoutBack = ['/personal-information'];

  /* eslint-disable  */
  React.useEffect(() => {
    setHeader({ title: props.title || '', subtitle: props.subtitle || '' });
  }, [props.title]);
  /* eslint-enable  */

  const handleSetEdit = (mode: boolean) => {
    if (props.setEditMode) props.setEditMode(mode);
  };

  if (!title && !props.showMenu) {
    return null;
  }

  return (
    <div
      className="prixa-header"
      style={
        theme
          ? {
              backgroundImage: `linear-gradient(to bottom right, ${(theme.colors && theme.colors.default) ||
                '#ffffff'}, ${(theme.colors && theme.colors.default) || '#ffffff'}aa`,
            }
          : {}
      }
    >
      {(!pageWithoutBack.includes(window.location.pathname) ||
        (pageWithoutBack.includes(window.location.pathname) && props.editMode !== true)) && (
        <div
          className="prixa-menuback"
          onClick={() => {
            UseTracking({ event: `Back clicked`, properties: { sessionId, state: currentState } });
            window.history.back();
          }}
        >
          <Icon type="faChevronLeft" style={{ width: 16 }} />
        </div>
      )}
      <div className="prixa-caption">
        <Text scale="headerTitle">
          {(() => {
            if (title) {
              return title;
            } else {
              return (
                <img
                  loading="lazy"
                  width="auto"
                  alt="logo"
                  className="prixa-header-image"
                  src={imgHeader}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    UseTracking({ event: `Logo clicked`, properties: { sessionId, state: currentState } });
                    if (localStorage.getItem('loginToken')) {
                      window.location.href = '/home';
                    } else {
                      window.location.href = '/';
                    }
                  }}
                />
              );
            }
          })()}
        </Text>
        {(() => {
          if (subtitle) {
            return <Text scale="headerSubtitle">{subtitle}</Text>;
          }
        })()}
      </div>
      <div className="prixa-menubar">
        {props.showMenu && (
          <span
            onClick={() => {
              UseTracking({ event: `Menu clicked`, properties: { sessionId, state: currentState } });
              setModal(true);
              openSideSheet();
            }}
          >
            <Icon color="primary" type="faBars" width="16px" />
          </span>
        )}

        {props.showFilter && (
          <Link to="/filter">
            <Icon color="primary" type="faSlidersH" width="16px" />
          </Link>
        )}

        {props.editMode === false && (
          <span
            style={{
              cursor: 'pointer',
              width: 24,
              height: 16,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#55b9e4',
            }}
            onClick={() => {
              UseTracking({ event: `Edit clicked`, properties: { sessionId, state: currentState } });
              handleSetEdit(true);
            }}
          >
            Edit
          </span>
        )}

        {props.editMode === true && (
          <span
            style={{
              cursor: 'pointer',
              width: 24,
              height: 16,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#55b9e4',
            }}
            onClick={() => {
              handleSetEdit(false);
              window.location.reload();
            }}
          >
            <Icon color="dark" type="faTimes" />
          </span>
        )}
      </div>
      <Sidesheet
        setModal={setModal}
        show={modal}
        title="Menu"
        className="prixa-sidesheet-menu"
        content={<MenuPage></MenuPage>}
      ></Sidesheet>
    </div>
  );
};

export default Header;
