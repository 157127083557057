import React from 'react';
import { Text, Card, IconSolid, Button, Sidesheet, openSideSheet } from 'prixa-design-kit/dist';
import { getAPI } from '../../../api/api-method';
import { DIAGNOSTIC_API } from '../../../api/api-url';
import ArticleSideSheet, { ArticleType } from '../../diagnostic/ArticleSideSheet';

interface ResultDiseasesListType {
  id: string;
  name: string;
  score: number;
  triage: 1 | 2;
  classify: 'tinggi' | 'rendah' | 'sedang';
  classifyLevel: 1 | 2;
  evidenceLevel: 'Kemungkinan Tinggi' | 'Kemungkinan Sedang' | 'Kemungkinan Rendah';
  article: string;
  description: string;
  prognosis?: string;
  isValidForRDT?: boolean;
}

interface WhatToDoNextProps {
  resultDiseases: Array<ResultDiseasesListType>;
  isValidForRDT: boolean;
}

const diseaseAccuracyMap = {
  high: 'tinggi',
  medium: 'sedang',
  low: 'rendah',
};

const classifyIconColor = {
  [diseaseAccuracyMap.high]: 'primary',
  [diseaseAccuracyMap.medium]: 'secondary',
  [diseaseAccuracyMap.low]: 'secondary',
};

const classifyTextColor: any = {
  [diseaseAccuracyMap.high]: 'pagesubtitle',
  [diseaseAccuracyMap.medium]: 'feedbackLink',
  [diseaseAccuracyMap.low]: 'feedbackLink',
};

export const ResultDiseasesList = ({ resultDiseases, isValidForRDT }: WhatToDoNextProps) => {
  const [modalArticle, setModalArticle] = React.useState<boolean>(false);
  const [article, setArticle] = React.useState<ArticleType | undefined>(undefined);
  const [articleTitle, setArticleTitle] = React.useState<string>('');
  const viewArticle = (id: string, name: string, url: string) => {
    getAPI(DIAGNOSTIC_API.DISEASE_ARTICLE(id)).then(async (res: any) => {
      const articleLink = { url: url };
      const articleData = { ...res, ...articleLink };
      setArticleTitle(name);
      setArticle(articleData);
      setModalArticle(true);
      openSideSheet();
    });
  };

  const list: Array<any> = [];

  resultDiseases.forEach((disease: any, i: number) => {
    const resultDisease = (
      <Card key={disease.name} style={{ margin: '0 40px 24px' }}>
        <div className="prixa-list-title">
          <Text scale="question">{disease.name}</Text>
        </div>
        {(() => {
          if (disease.description) {
            return (
              <div className="prixa-list-subtitle prixa-disease-short-description">
                <Text scale="content">{disease.description}</Text>
              </div>
            );
          }
        })()}

        <div>
          <div className="prixa-disease-advance-info margin-smallT">
            <IconSolid
              backgroundColor={classifyIconColor[disease.classify]}
              backgroundSize="18px"
              color="white"
              margin="0px"
              type="faPercentage"
              width="8px"
              style={{ alignItems: 'center' }}
            >
              <Text
                className="margin-microL"
                style={{ lineHeight: '17px' }}
                scale={classifyTextColor[disease.classify]}
              >
                {disease.evidenceLevel}
              </Text>
            </IconSolid>
          </div>

          <div className="prixa-disease-advance-info">
            <IconSolid
              backgroundColor={'danger'}
              backgroundSize="18px"
              color="white"
              margin="0px"
              type="faExclamation"
              width="4px"
              style={{ alignItems: 'center' }}
            >
              <Text className="margin-microL" style={{ lineHeight: '17px', flex: 1 }} scale={'headerSubtitle'}>
                {disease.prognosis}
              </Text>
            </IconSolid>
          </div>
        </div>

        {(() => {
          if (disease.article !== '#') {
            return (
              <span className="prixa-list-button">
                <Button
                  onClick={() => viewArticle(disease.id, disease.name, disease.article)}
                  size="small"
                  className="dav-special"
                  variant="outline"
                >
                  Lebih Lanjut
                </Button>
              </span>
            );
          }
        })()}
      </Card>
    );

    if (i < 3) list.push(resultDisease);
  });
  return (
    <React.Fragment>
      {list}

      {article && (
        <Sidesheet
          setModal={setModalArticle}
          show={modalArticle}
          title={articleTitle}
          content={<ArticleSideSheet {...article} isValidForRDT={isValidForRDT}></ArticleSideSheet>}
        ></Sidesheet>
      )}
    </React.Fragment>
  );
};

export default ResultDiseasesList;
